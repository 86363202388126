
export const formatDate = (row) => {
  let date = row;
  date = new Date(date);
  const day = date.getUTCDate()?.toString()?.length >= 2 ? date.getUTCDate() : `0${date.getUTCDate()}`;
  return date ? `${date.getUTCMonth() + 1}/${day}/${date.getUTCFullYear()}` : '';
};

export const displayPhoneNumber = (row) => {
  const phone = row?.phone ? row?.phone : row?.registeredPhone ? row?.registeredPhone : '';
  return phone ? `(${phone?.toString().slice(0, 3)}) ${phone?.toString().slice(3, 6)}-${phone?.toString().slice(6)}` : '';
};

export const validRegex = new RegExp(/\S+@\S+\.\S+/);
export const phoneNumberRegex = /^\d{10}$/;

import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';

import { Chip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'component/LoadingOverlay';
import AgencyDialog from 'component/AgencyDialog';

const agencyColumns = [
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'companyName',
    label: 'Company Name'
  }
];

const AgencyQuote = ({ companyId, activeStep, selectedAgency, emitSelectedAgency }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [agencies, setAgencies] = useState([]);

  const handleSave = async (agencyDialog) => {
    setIsLoading(true);
    const response = await axios.post(`/api/agency/companyId/${companyId}`, {
      agency: {
        ...agencyDialog,
        idCompany: +companyId
      }
    });
    emitSelectedAgency(activeStep, response.data);
    setIsLoading(false);
  };

  const handleClickOpendialog = () => {
    setOpen(true);
  };

  const handleAgencySelect = (row) => {
    emitSelectedAgency(activeStep, row);
  };

  const handleClosedialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getAgencies = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/agency/companyId/${companyId}`);
      setAgencies(result.data);
      setIsLoading(false);
    };

    getAgencies();
  }, [companyId]);

  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        isLoading={isLoading}
        columns={agencyColumns}
        data={agencies}
        hasClickableRows={true}
        emitClickedRow={handleAgencySelect}
        title="Agency"
        hasPagination={false}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <Chip
            label="Select"
            color="primary"
            variant={selectedAgency.id === row.id ? 'filled' : 'outlined'}
            sx={{ color: selectedAgency.id === row.id ? '#fff' : '#000' }}
            clickable
          />
        )}
      </CustomTable>
      <AgencyDialog actionType="Create" open={open} handleClosedialog={handleClosedialog} handleAgencySave={handleSave} agency={{}} />
    </>
  );
};

export default AgencyQuote;

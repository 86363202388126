// react Imports
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// Mui Imports
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  MenuItem,
  TextField,
  Button,
  Input,
  InputAdornment,
  Tooltip,
  IconButton
} from '@mui/material';
import Breadcrumb from 'component/Breadcrumb';

// Local Imports
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';
import AlertDialogSlide from 'views/Elements/Advance/UIDialog/AlertDialogSlide';
import { formatDate, validRegex, phoneNumberRegex } from 'utils/FormatUtils';
// Icons

import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import { FileUploadOutlined, FileDownloadOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import ClaimPDF from '../ClaimPDF';
import { usePDF } from '@react-pdf/renderer';
import { deactiveArrayUtil } from 'utils/deactiveArrayUtil';

const columns = [
  {
    id: 'id',
    label: 'Id'
  },
  {
    id: 'fileName',
    label: 'File Name'
  }
];

const ClaimDetails = () => {
  // Initialize Axios
  const auth = useAuth();
  const axios = initializeAxios(auth);
  // Reactive States
  const { companyId, claimId } = useParams();

  const [claimDetails, setClaimDetails] = useState({});
  const [calculateTotal, setCalculateTotal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [customerContacts, setCustomerContacts] = useState([]);
  const [aircrafts, setAircrafts] = useState([]);
  const [claimStatus, setClaimStatus] = useState([]);
  const [users, setUsers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [agencyId, setAgencyId] = useState(null);
  const [aircraftId, setAircraftId] = useState(null);
  const [policyId, setPolicyId] = useState(null);
  const [idImmutable, setIdImmutable] = useState(null);
  const [customerOrgId, setCustomerOrgId] = useState(null);
  const [file, setFile] = useState();
  const [open, setOpen] = useState(false);
  const [deletedDocument, setDeletedDocument] = useState({});

  const creationDate = new Date();
  const pdfContent = <ClaimPDF claim={claimDetails} creationDate={creationDate} />;
  const [instance, updateInstance] = usePDF(pdfContent);

  // Claim Details Methods

  // Save Changes
  const handleSave = async () => {
    setIsLoading(true);
    if (claimDetails?.location?.trim() && claimDetails?.description?.trim()) {
      const response = await axios.put(`/api/claim/${claimId}/companyId/${companyId}`, {
        claim: {
          idPilot: claimDetails?.idPilot,
          idAgent: claimDetails?.idAgent,
          idAgency: claimDetails?.idAgency,
          idCompanyUser: claimDetails?.idCompanyUser,
          idAircraft: claimDetails?.idAircraft,
          idCustomerContact: claimDetails?.idCustomerContact,
          claimDate: claimDetails?.claimDate,
          idClaimStatus: claimDetails?.idClaimStatus,
          location: claimDetails?.location,
          description: claimDetails?.description,
          phone: claimDetails?.phone,
          email: claimDetails?.email,
          notes: claimDetails?.notes,
          hullDamage: claimDetails?.hullDamage,
          bodilyInjury: claimDetails?.bodilyInjury,
          propertyDamage: claimDetails?.propertyDamage,
          adjustmentExpense: claimDetails?.adjustmentExpense,
          allocatedReserve: claimDetails?.allocatedReserve,
          recovery: claimDetails?.recovery
        }
      });
      const newClaimDetails = response.data;
      setClaimDetails({
        ...newClaimDetails
      });
    }
    setIsLoading(false);
  };

  // File Methods

  // formats the files
  const formatDocuments = (array) => {
    if (Array.isArray(array)) {
      const tempArray = [];
      array.forEach((item) => {
        const obj = {
          id: item.Id,
          fileName: item.fileName
        };
        tempArray.push(obj);
      });
      setDocuments([...tempArray]);
    } else {
      const obj = {
        id: array.Id,
        fileName: array.fileName
      };
      setDocuments([...documents, obj]);
    }
  };

  // Saves File to DB and updates Table
  const handleFileSave = async (blob = null, flag = false) => {
    setIsLoading(true);
    const formData = new FormData();
    if (blob && flag) {
      const date = creationDate.toLocaleDateString().split('/');
      const blobFile = new File([blob], `claim-${claimId}-${date[0]}${date[1]}${date[2].substring(2)}.pdf`);
      formData.append('file', blobFile);
    } else {
      formData.append('file', file);
    }
    const result = await axios.post(`/api/claim/${claimId}/file/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    formatDocuments(result.data);
    setIsLoading(false);
  };

  // Method for Deleting Documebt
  const handleDelete = async (deleteDocument) => {
    await axios.delete(`/api/claim/${claimId}/file/${deleteDocument.id}`);
    const tempArray = [...documents];
    const index = tempArray.findIndex((e) => e.id === deleteDocument.id);
    tempArray.splice(index, 1);
    setDocuments(tempArray);
  };

  // Downloading File
  const handleDownload = async (downloadFile) => {
    const response = await axios.get(`/api/claim/${claimId}/file/${downloadFile.id}/download`, {
      responseType: 'blob'
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response.data);
    link.download = downloadFile.fileName;
    link.click();
    link.remove();
  };

  // Calculation Methods

  const calculateTotalFunc = () => {
    const total = [];
    Object.keys(claimDetails).forEach((item) => {
      if (
        item === 'hullDamage' ||
        item === 'bodilyInjury' ||
        item === 'propertyDamage' ||
        item === 'allocatedReserve' ||
        item === 'adjustmentExpense' ||
        item === 'recovery'
      ) {
        total.push(claimDetails[item]);
      }
    });
    const amount = total?.reduce((total, next) => total + (next ? +next : 0), 0);
    setCalculateTotal(amount.toString());
  };

  // Modal Methods

  const handleCloseModal = () => {
    setOpen(false);
    setDeletedDocument({});
  };

  const handleDeleteMiddleware = (row) => {
    setDeletedDocument(row);
    setOpen(true);
  };

  // handle Change Methods
  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === 'idAgency') {
      setAgencyId(value);
      setClaimDetails({
        ...claimDetails,
        idAgent: '',
        [name]: value
      });
    } else if (name === 'idAircraft') {
      setAircraftId(value);
      setClaimDetails({
        ...claimDetails,
        idPilot: '',
        [name]: value
      });
    } else {
      setClaimDetails({
        ...claimDetails,
        [name]: value
      });
    }
  };

  const handleDateChange = (date) => {
    setClaimDetails({
      ...claimDetails,
      claimDate: date !== '' ? date : null
    });
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Use Effets
  useEffect(() => {
    updateInstance(<ClaimPDF claim={claimDetails} creationDate={creationDate} />);
  }, [creationDate, claimDetails]);

  useEffect(() => {
    const getCustomerContacts = async () => {
      const response = await axios.get(`/api/customer-contact/customerOrgId/${customerOrgId}/companyId/${companyId}`);
      setCustomerContacts([...response.data]);
    };
    const getAircraftRelations = async () => {
      const response = await axios.get(`/api/policy/${policyId}/aircraft/companyId/${companyId}`);
      console.log('Aircrafts: ', response.data);
      setAircrafts([...response.data]);
    };
    if (customerOrgId) {
      getCustomerContacts();
    }

    if (policyId) {
      getAircraftRelations();
    }
  }, [customerOrgId, policyId]);

  useEffect(() => {
    if (claimDetails) {
      calculateTotalFunc();
    }
  }, [
    claimDetails?.hullDamage,
    claimDetails?.bodilyInjury,
    claimDetails?.propertyDamage,
    claimDetails?.allocatedReserve,
    claimDetails?.adjustmentExpense,
    claimDetails?.recovery
  ]);
  useEffect(() => {
    const getClaim = async () => {
      setIsLoading(true)
      const response = await axios.get(`/api/claim/${claimId}/companyId/${companyId}`);
      const claim = response.data;
      setClaimDetails({
        ...claim
      });
      setAgencyId(claim?.idAgency);
      setAircraftId(claim?.idAircraft);
      setPolicyId(claim?.Policy?.id);
      setIdImmutable(claim?.Policy?.idImmutable);
      setCustomerOrgId(claim?.Policy?.idCustomerOrganization);
      formatDocuments([...response.data.ClaimDocument]);
      setIsLoading(false);
    };
    const getClaimStatus = async () => {
      const response = await axios.get(`/api/options/companyId/${companyId}?category=ClaimStatus`);
      setClaimStatus([...response.data]);
    };
    const getCompanyUserProfiles = async () => {
      const result = await axios.get(`/api/company/${companyId}/users`);
      setUsers(result.data.UserProfile);
    };
    const getAgencies = async () => {
      const response = await axios.get(`/api/agency/companyId/${companyId}`);
      setAgencies([...response.data]);
    };

    const getAgents = async () => {
      const response = await axios.get(`/api/agent/companyId/${companyId}`);
      setAgents([...response.data]);
    };

    getClaim();
    getClaimStatus();
    getCompanyUserProfiles();
    getAgencies();
    getAgents();
  }, []);
  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ mt: { lg: 0, xs: 1 } }}>
          <Breadcrumb title="Claim Details" divider={false}>
            <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
              Home
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/policy/`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Policies
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/policy/${idImmutable}`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Policy Details
            </Typography>
            <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
              Claim Details
            </Typography>
          </Breadcrumb>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <Grid item lg={8} xs={12}>
        <Grid container spacing={gridSpacing} xs={12} item sx={{ flexWrap: { lg: 'nowrap', sm: 'wrap' } }} className="grid-container">
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Card>
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Claim Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disableToolbar
                            variant="inline"
                            inputFormat="MM/dd/yyyy"
                            margin="normal"
                            label="Date of Claim"
                            isRequired
                            readOnly={!auth.writePrivledge(auth.user, 'Policy_Adm')}
                            renderInput={(props) => (
                              <TextField
                                error={claimDetails?.claimDate}
                                helperText={!claimDetails?.claimDate ? 'Cannot be Empty' : ''}
                                fullWidth
                                {...props}
                                sx={{ mt: 1 }}
                              />
                            )}
                            value={formatDate(claimDetails?.claimDate) || null}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            keyboardIcon={<EventTwoToneIcon />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      {aircrafts.length > 0 && (
                        <Grid item lg={6} xs={12}>
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Aircraft"
                            name="idAircraft"
                            select
                            value={claimDetails?.idAircraft || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                            }}
                            onChange={handleChange}
                          >
                            {aircrafts?.map((aircraft) => (
                              <MenuItem key={aircraft?.idAircraft} value={aircraft?.idAircraft}>
                                {aircraft?.Aircraft?.registrationNo}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                      <Grid item lg={4} xs={12}>
                        {policyId && aircraftId && aircrafts?.length > 0 && (
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            label="Pilot"
                            name="idPilot"
                            select
                            value={claimDetails?.idPilot || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                            }}
                            onChange={handleChange}
                          >
                            {aircrafts
                              .filter((item) => item.idAircraft === aircraftId)[0]
                              ?.AircraftPilotDetails?.map((pilot) => (
                                <MenuItem key={pilot?.PilotDetails?.idPilot} value={pilot?.PilotDetails?.idPilot}>
                                  {`${pilot?.PilotDetails?.firstname} ${pilot?.PilotDetails?.lastname}`}
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Agency Name"
                          name="idAgency"
                          select
                          value={claimDetails?.idAgency || ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                          }}
                          onChange={handleChange}
                        >
                          {deactiveArrayUtil(agencies, {
                            id: claimDetails?.idAgency,
                            name: claimDetails?.Agency?.name
                          })?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Agent Display Name"
                          name="idAgent"
                          select
                          value={claimDetails?.idAgent || ''}
                          disabled={!claimDetails?.idAgency}
                          error={!claimDetails?.idAgent}
                          helperText={!claimDetails?.idAgent ? 'Cannot be Empty' : ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                          }}
                          onChange={handleChange}
                        >
                          {deactiveArrayUtil(agents, {
                            id: claimDetails?.idAgent,
                            displayName: claimDetails?.Agent?.displayName
                          })
                            ?.filter((item) => item.idAgency === agencyId)
                            .map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.displayName}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Company User"
                          name="idCompanyUser"
                          select
                          value={claimDetails?.idCompanyUser || ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                          }}
                          onChange={handleChange}
                        >
                          {deactiveArrayUtil(users, {
                            id: claimDetails?.idAgency,
                            displayName: claimDetails?.['UserProfile_Claim_idCompanyUserToUserProfile']?.displayName
                          })?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.displayName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        fullWidth
                        label="Location"
                        name="location"
                        variant="outlined"
                        multiline
                        rows={6}
                        value={claimDetails?.location || ''}
                        error={!claimDetails?.location?.trim()}
                        helperText={!claimDetails?.location?.trim() ? 'location cannot be empty' : ''}
                        InputProps={{
                          readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        fullWidth
                        label="description"
                        name="description"
                        variant="outlined"
                        multiline
                        rows={6}
                        value={claimDetails?.description || ''}
                        error={!claimDetails?.description?.trim()}
                        helperText={!claimDetails?.description?.trim() ? 'Description cannot be empty' : ''}
                        InputProps={{
                          readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography component="div" variant="h5">
                          Claim Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {customerContacts?.length && (
                          <Typography component="span" variant="subtitle2">
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              label="Customer"
                              name="idCustomerContact"
                              select
                              value={claimDetails?.idCustomerContact || ''}
                              error={!claimDetails?.idCustomerContact}
                              helperText={!claimDetails?.idCustomerContact ? 'Cannot be Empty' : ''}
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                              }}
                              onChange={handleChange}
                            >
                              {deactiveArrayUtil(customerContacts, {
                                id: claimDetails?.idCustomerContact,
                                name: claimDetails?.CustomerContact?.name
                              }).map((cc) => (
                                <MenuItem key={cc.id} value={cc.id}>
                                  {cc.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Typography>
                        )}
                        <Typography component="span" variant="subtitle2">
                          <TextField
                            fullWidth
                            sx={{ mt: 2 }}
                            label="Customer Email"
                            name="email"
                            variant="outlined"
                            input={<Input />}
                            value={claimDetails?.email || ''}
                            error={claimDetails?.email?.length > 0 ? !claimDetails?.email?.match(validRegex) : false}
                            helperText={
                              claimDetails?.email?.length > 0 ? (!claimDetails?.email?.match(validRegex) ? 'Invalid Emial' : '') : ''
                            }
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                            }}
                            onChange={handleChange}
                          />
                        </Typography>
                        <Typography component="span" variant="subtitle2">
                          <TextField
                            fullWidth
                            sx={{ mt: 2 }}
                            label="Customer Phone"
                            name="phone"
                            variant="outlined"
                            value={claimDetails.phone || ''}
                            onChange={handleChange}
                            error={claimDetails?.phone?.length > 0 ? !claimDetails?.phone?.match(phoneNumberRegex) : false}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                            }}
                            helperText={
                              claimDetails?.phone?.length > 0
                                ? !claimDetails?.phone?.match(phoneNumberRegex)
                                  ? 'Invalid Phone Number'
                                  : ''
                                : ''
                            }
                          />
                        </Typography>
                        <Typography component="span" variant="subtitle2">
                          <TextField
                            sx={{ mt: 2 }}
                            fullWidth
                            label="Notes"
                            name="notes"
                            variant="outlined"
                            multiline
                            rows={11.2}
                            value={claimDetails?.notes || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                            }}
                            onChange={handleChange}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} xs={12} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography component="div" variant="subtitle1">
                          Hull Damage : &nbsp;
                          <Typography component="span" variant="subtitle2">
                            <TextField
                              fullWidth
                              id="filled-start-adornment1"
                              name="hullDamage"
                              value={claimDetails?.hullDamage || ''}
                              type="number"
                              onChange={handleChange}
                              InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                              }}
                              variant="outlined"
                            />
                          </Typography>
                        </Typography>
                        <Typography component="div" variant="subtitle1" sx={{ mt: 1 }}>
                          Bodily Injury : &nbsp;
                          <Typography component="span" variant="subtitle2">
                            <TextField
                              fullWidth
                              id="filled-start-adornment1"
                              name="bodilyInjury"
                              value={claimDetails?.bodilyInjury || ''}
                              type="number"
                              onChange={handleChange}
                              InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                              }}
                              variant="outlined"
                            />
                          </Typography>
                        </Typography>
                        <Typography component="div" variant="subtitile1">
                          Property Damage : &nbsp;
                          <Typography component="span" variant="subtitle2">
                            <TextField
                              fullWidth
                              id="filled-start-adornment1"
                              name="propertyDamage"
                              value={claimDetails?.propertyDamage || ''}
                              type="number"
                              onChange={handleChange}
                              InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                              }}
                              variant="outlined"
                            />
                          </Typography>
                        </Typography>
                        <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                          Allocated Reserve : &nbsp;
                          <Typography component="span" variant="subtitle2">
                            <TextField
                              fullWidth
                              id="filled-start-adornment1"
                              name="allocatedReserve"
                              value={claimDetails?.allocatedReserve || ''}
                              type="number"
                              onChange={handleChange}
                              InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                              }}
                              variant="outlined"
                            />
                          </Typography>
                        </Typography>
                        <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                          Adjustment Expense : &nbsp;
                          <Typography component="span" variant="subtitle2">
                            <TextField
                              fullWidth
                              id="filled-start-adornment1"
                              name="adjustmentExpense"
                              value={claimDetails?.adjustmentExpense || ''}
                              type="number"
                              onChange={handleChange}
                              InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                              }}
                              variant="outlined"
                            />
                          </Typography>
                        </Typography>
                        <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                          Recovery : &nbsp;
                          <Typography component="span" variant="subtitle2">
                            <TextField
                              fullWidth
                              id="filled-start-adornment1"
                              name="recovery"
                              value={claimDetails?.recovery || ''}
                              type="number"
                              onChange={handleChange}
                              InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                              }}
                              variant="outlined"
                            />
                          </Typography>
                        </Typography>
                        <Typography component="div" variant="subtitle1" sx={{ mt: 2 }}>
                          Total
                        </Typography>
                        <Typography component="span" variant="subtitle2">
                          <TextField
                            fullWidth
                            id="filled-start-adornment1"
                            name="recovery"
                            value={calculateTotal || '0'}
                            type="number"
                            InputProps={{
                              inputProps: { min: 0 },
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                            }}
                            variant="outlined"
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <AlertDialogSlide
                message="Confirm if you want to delete Claim Document"
                handleCloseDialog={handleCloseModal}
                handleResult={handleDelete}
                emitValue={deletedDocument}
                open={open}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <Typography component="div" variant="h5">
                      Claim Status
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid item xs={6}>
                      <Typography component="span" variant="h1">
                        {claimDetails?.Options?.val}
                      </Typography>
                      <Typography component="span" variant="h1" sx={{ pl: 6 }}>
                        {claimDetails?.idClaimStatus === 31 ? formatDate(claimDetails?.dateClosed) : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ pb: 4 }}>
                      <Typography component="div" variant="subtitle1" sx={{ pl: 1 }}>
                        Change Claim Status : &nbsp;
                        <Typography component="span" variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            name="idClaimStatus"
                            select
                            value={claimDetails?.idClaimStatus || ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                            }}
                            onChange={handleChange}
                          >
                            {claimStatus?.map((status) => (
                              <MenuItem key={status.id} value={status.id}>
                                {status.val}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                      <Grid item sm={6} md={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSave}
                          disabled={
                            !claimDetails?.claimDate ||
                            !claimDetails?.location?.trim()?.length ||
                            !claimDetails?.description?.trim()?.length ||
                            !claimDetails?.idAgent ||
                            !auth.writePrivledge(auth.user, 'Policy_Adm')
                          }
                        >
                          Save Changes
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <Typography component="div" variant="h5">
                      Documents
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ display: { lg: 'flex', xs: 'block' }, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid item lg={6} xs={12}>
                      <Button variant="contained" color="primary" onClick={() => handleFileSave(instance.blob, true)}>
                        Save Claim PDF
                      </Button>
                    </Grid>
                    <Grid item>
                      <TextField
                        variant="standard"
                        type="text"
                        value={file?.name || ''}
                        InputProps={{
                          endAdornment: (
                            <IconButton component="label">
                              <FileUploadOutlined />
                              <input style={{ display: 'none' }} type="file" hidden onChange={handleFileChange} name="[licenseFile]" />
                            </IconButton>
                          )
                        }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!file?.name || !auth.writePrivledge(auth.user, 'Policy_Adm')}
                        onClick={handleFileSave}
                        sx={{ ml: { lg: 2, xs: 0 } }}
                      >
                        Upload File
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTable isLoading={false} columns={columns} data={documents}>
                      {(row, index) => (
                        <>
                          <Tooltip title="Download Document" placement="top">
                            <span>
                              <IconButton
                                color="primary"
                                aria-label="Download"
                                size="large"
                                onClick={() => handleDownload(documents[index])}
                              >
                                <FileDownloadOutlined />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Delete Claim" placement="top">
                            <span>
                              <IconButton
                                color="secondary"
                                aria-label="Edit"
                                size="large"
                                disabled={!auth.writePrivledge(auth.user, 'Policy_Adm')}
                                onClick={() => handleDeleteMiddleware(row)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </>
                      )}
                    </CustomTable>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClaimDetails;

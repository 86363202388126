import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import initializeAxios from 'utils/axios';

// material-ui
import { Typography, IconButton, Tooltip } from '@mui/material';
import useAuth from 'hooks/useAuth';
import CustomTable from 'component/CustomTable';

// project import
import Breadcrumb from 'component/Breadcrumb';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LoadingOverlay from 'component/LoadingOverlay';
import LienholderDialog from 'component/Lienholder';

// ==============================|| lienHolder LIST ||============================== //

const LienHolder = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const columns = [
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'address1',
      label: 'Address 1'
    },
    {
      id: 'state',
      label: 'State'
    },
    {
      id: 'zip',
      label: 'Zipcode'
    },
    {
      id: 'companyName',
      label: 'Company Name'
    }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lienHolders, setLienHolders] = useState([]);
  const [actionType, setActionType] = useState('');
  const [lienHolderId, setLienHolderId] = useState(null);
  const { companyId } = useParams();
  const [lienholder, setLienholder] = useState({});

  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
    setLienholder({});
  };

  const handleSave = async (lienHolderDialog) => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/lienholder/companyId/${companyId}`, {
          ...lienHolderDialog
        });
        const newlienHolder = {
          ...response.data
        };
        setLienHolders([...lienHolders, newlienHolder]);

        break;
      }

      case 'Edit': {
        const response = await axios.put(`/api/lienholder/${lienHolderId}/companyId/${companyId}`, {
          ...lienHolderDialog
        });
        const tempArray = [...lienHolders];
        const index = lienHolders.findIndex((e) => e.id === lienHolderId);
        tempArray[index] = {
          ...response.data
        };
        setLienHolders(tempArray);

        break;
      }

      case 'Delete': {
        await axios.delete(`/api/lienholder/${lienHolderId}/companyId/${companyId}`);
        const tempArray = [...lienHolders];
        const index = lienHolders.findIndex((e) => e.id === lienHolderId);
        tempArray.splice(index, 1);
        setLienHolders(tempArray);
        break;
      }
    }
    handleClosedialog();
  };

  const handleEdit = (editlienHolder) => {
    setLienholder({
      ...editlienHolder
    });
    setActionType('Edit');
    setLienHolderId(editlienHolder.id);
    setOpen(true);
  };

  const handleDelete = (deleteUser) => {
    setLienHolderId(deleteUser.id);
    setActionType('Delete');
    setOpen(true);
  };

  useEffect(() => {
    const getCustomerOrg = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/lienHolder/companyId/${companyId}`);
      setLienHolders([...result.data]);
      setIsLoading(false);
    };

    getCustomerOrg();
  }, [companyId]);

  return (
    <>
      <Breadcrumb title="Other Interests" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Other Interests
        </Typography>
      </Breadcrumb>
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        title="Other Interests"
        isLoading={isLoading}
        data={lienHolders}
        columns={columns}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
        isDisabled={!auth.writePrivledge(auth.user, 'Leinholder_Adm')}
      >
        {(row, index) => (
          <>
            <Tooltip title="Edit Interest">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Leinholder_Adm')}
                  onClick={() => handleEdit(lienHolders[index])}
                >
                  <EditTwoToneIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Interest">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Leinholder_Adm')}
                  onClick={() => handleDelete(lienHolders[index])}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </CustomTable>
      <LienholderDialog
        open={open}
        handleClosedialog={handleClosedialog}
        lienholderState={lienholder}
        handleLienholderSave={handleSave}
        actionType={actionType}
      />
    </>
  );
};

export default LienHolder;

import { useNavigate, useParams } from 'react-router';
import React, { useState } from 'react';
import { Box, Typography, Stepper, Step, StepLabel, Button, useTheme, useMediaQuery, MobileStepper } from '@mui/material';
import AgentQuote from '../../component/AgentQuote';
import AgencyQuote from '../../component/AgencyQuote';
import AirportQuoteCustomerOrg from '../../component/CustomerOrgQuote';
import AirportCustomerContact from '../../component/CustomerContactQuote';
import AirportQuoteComponent from './Airport';
import AirportQuotePolicy from 'component/PolicyQuote';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const steps = ['Agency', 'Agent', 'Customer Organization', 'Customer Contact', 'Airport', 'Policy'];
const AirportQuote = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const { companyId } = useParams();

  const [selectedAgency, setSelectedAgency] = useState({});
  const [selectedAgent, setSelectedAgent] = useState({});
  const [selectedCustomerOrg, setSelectedCustomerOrg] = useState({});
  const [selectedCustomerContact, setSelectedCustomerContact] = useState({});
  const [selectedAirport, setSelectedAirport] = useState([]);

  const handleFinish = (row) => {
    navigate(`/company/${companyId}/policy/${row.id}`);
  };

  const handleSelect = (emitActiveStep, row) => {
    console.log('Handle Select', emitActiveStep, row);
    switch (emitActiveStep) {
      case 0: {
        setSelectedAgency(row);
        handleNext();
        break;
      }
      case 1: {
        setSelectedAgent(row);
        handleNext();
        break;
      }
      case 2: {
        setSelectedCustomerOrg(row);
        handleNext();
        break;
      }
      case 3: {
        setSelectedCustomerContact(row);
        handleNext();
        break;
      }
      case 4: {
        setSelectedAirport(row);
        handleNext();
        break;
      }
      case 5: {
        handleFinish(row);
        break;
      }
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" sx={{ mb: 2, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        Airport Quote
      </Typography>
      {!isMobile && (
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ mt: 4 }}>
            {activeStep === 0 && (
              <AgencyQuote
                companyId={companyId}
                selectedAgency={selectedAgency}
                activeStep={activeStep}
                emitSelectedAgency={handleSelect}
              />
            )}
            {activeStep === 1 && (
              <AgentQuote
                companyId={companyId}
                selectedAgency={selectedAgency}
                selectedAgent={selectedAgent}
                activeStep={activeStep}
                emitSelectedAgent={handleSelect}
              />
            )}
            {activeStep === 2 && (
              <AirportQuoteCustomerOrg
                companyId={companyId}
                selectedCustomerOrg={selectedCustomerOrg}
                activeStep={activeStep}
                emitSelectedCustomerOrg={handleSelect}
              />
            )}
            {activeStep === 3 && (
              <AirportCustomerContact
                companyId={companyId}
                selectedCustomerContact={selectedCustomerContact}
                selectedCustomerOrg={selectedCustomerOrg}
                activeStep={activeStep}
                emitSelectedCustomerContact={handleSelect}
              />
            )}
            {activeStep === 4 && (
              <AirportQuoteComponent
                selectedAgency={selectedAgency}
                selectedAgent={selectedAgent}
                selectedAirport={selectedAirport}
                activeStep={activeStep}
                emitAirportSelect={handleSelect}
              />
            )}
            {activeStep === 5 && (
              <AirportQuotePolicy
                companyId={companyId}
                selectedAgency={selectedAgency}
                selectedAgent={selectedAgent}
                selectedContact={selectedCustomerContact}
                selectedCustomerOrg={selectedCustomerOrg}
                selectedAirport={selectedAirport}
                handleNext={handleNext}
                emitSelectedPolicy={handleSelect}
                activeStep={activeStep}
                isAirport={true}
              />
            )}
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {isMobile ? (
              <MobileStepper
                variant="progress"
                steps={6}
                position="static"
                activeStep={activeStep}
                sx={{ maxWidth: 400, flexGrow: 1 }}
                nextButton={
                  <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
                    Next
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </Button>
                }
                backButton={
                  <Button size="small" disabled={activeStep === 0} onClick={handleBack}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    Back
                  </Button>
                }
              />
            ) : (
              <>
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {activeStep !== 5 && <Button onClick={handleNext}>Next</Button>}
              </>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default AirportQuote;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import {
  Grid,
  Typography,
  IconButton,
  Fab,
  Button,
  TextField,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tooltip,
} from '@mui/material';

// project import
import Breadcrumb from 'component/Breadcrumb';
import { gridSpacing } from 'config.js';
import initializeAxios from 'utils/axios';
import CustomTable from 'component/CustomTable';

// assets
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import '../../../assets/scss/actions.scss';
import useAuth from 'hooks/useAuth';
import LoadingOverlay from 'component/LoadingOverlay';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// ==============================|| CONTACT LIST ||============================== //
const columns = [
  {
    id: 'id',
    label: 'Id'
  },
  {
    id: 'category',
    label: 'Category'
  },
  {
    id: 'val',
    label: 'value'
  }
];
const Options = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setOptionsObj({});
  };

  const handleSave = async () => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post('/api/options', { optionsObj });
        const newOption = { ...response.data };
        setOptionsObjs([...optionsObjs, newOption]);
        break;
      }
      case 'Edit': {
        const response = await axios.put(`/api/options/${optionsObjId}`, { optionsObj });
        const tempArray = [...optionsObjs];
        const index = optionsObjs.findIndex((e) => e.id === optionsObjId);
        tempArray[index] = {
          ...response.data
        };
        setOptionsObjs(tempArray);
        break;
      }

      case 'Delete': {
        await axios.delete(`/api/options/${optionsObjId}`);
        const tempArray = [...optionsObjs];
        const index = tempArray.findIndex((e) => e.id === optionsObjId);
        tempArray.splice(index, 1);
        setOptionsObjs(tempArray);
        break;
      }
    }

    handleClosedialog();
  };

  const [optionsObj, setOptionsObj] = useState({
    val: '',
    category: '',
    sortIndex: 0
  });
  const [actionType, setActionType] = useState('');
  const [optionsObjs, setOptionsObjs] = useState([]);
  const [optionsObjId, setOptionsObjId] = useState(null);

  const handleChange = (event) => {
    const value = event.target.value;
    setOptionsObj({
      ...optionsObj,
      [event.target.name]: value
    });
  };
  const handleEdit = (editOptionsObj) => {
    setOptionsObj({
      val: editOptionsObj.val,
      category: editOptionsObj.category
    });
    setOptionsObjId(editOptionsObj.id);
    setActionType('Edit');
    setOpen(true);
  };

  const handleDelete = (deleteOptionsObj) => {
    setOptionsObjId(deleteOptionsObj.id);
    setActionType('Delete');
    setOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get('/api/options/all')
      .then((response) => {
        setOptionsObjs(response.data);
      })
      .then(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Breadcrumb title="List of Options" sx={{mt: {xs: 2, lg: 0}}}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Options
        </Typography>
      </Breadcrumb>
      <Grid container wrap="nowrap" justifyContent="flex-end" sx={{ mb: 3 }} spacing={gridSpacing}>
        <Grid item>
          <Tooltip title="Add a Option">
            <Fab color="primary" aria-label="Add" size="small" onClick={handleClickOpendialog}>
              <AddIcon />
            </Fab>
          </Tooltip>

          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClosedialog}
            sx={{
              '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                '& .MuiDialog-paper': {
                  m: 0,
                  borderRadius: 0,
                  maxWidth: 450,
                  maxHeight: '100%',
                  height: '100vh',
                  width: {xs: '100vw'}
                }
              }
            }}
          >
            <DialogTitle>
              {actionType === 'Create'
                ? 'New Option'
                : actionType === 'Edit'
                ? 'Edit Option'
                : actionType === 'Delete'
                ? 'Delete Option'
                : ''}
            </DialogTitle>
            <DialogContent>
              {actionType !== 'Delete' ? (
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="val"
                      label="Value"
                      variant="outlined"
                      onChange={handleChange}
                      required
                      error={!optionsObj?.val?.trim()}
                      helperText={!optionsObj?.val?.trim() ? 'Value cannot be empty' : ''}
                      value={optionsObj.val || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="category"
                      label="Category"
                      variant="outlined"
                      onChange={handleChange}
                      required
                      error={!optionsObj?.category?.trim()}
                      helperText={!optionsObj?.category?.trim() ? 'Category cannot be empty' : ''}
                      value={optionsObj.category || ''}
                    />
                  </Grid>
                </Grid>
              ) : (
                <DialogContentText>Confirm if you want to delete this Option</DialogContentText>
              )}
            </DialogContent>
            <DialogActions className="actions">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={actionType !== 'Delete' ? !optionsObj?.category?.trim() || !optionsObj?.val?.trim() : false}
              >
                {actionType !== 'Delete' ? 'Save' : 'Delete'}
              </Button>
              <Button variant="text" onClick={handleClosedialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <CustomTable title="Options" data={optionsObjs} columns={columns} isLoading={isLoading}>
        {(row, index) => (
          <>
            <Tooltip title="Edit Option" placement="top">
              <IconButton color="primary" aria-label="Edit" size="large" onClick={() => handleEdit(optionsObjs[index])}>
                <EditTwoToneIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Option" placement="top">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleDelete(optionsObjs[index])}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default Options;

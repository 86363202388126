
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import initializeAxios from 'utils/axios';

// material-ui
import { Grid, Typography, IconButton, Tooltip } from '@mui/material';
import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';

// project import
import Breadcrumb from 'component/Breadcrumb';
import { gridSpacing } from 'config.js';
// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PilotDialog from 'component/PilotDialog';
import LoadingOverlay from 'component/LoadingOverlay';

// ==============================|| CONTACT LIST ||============================== //
const Pilots = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const axios = initializeAxios(auth);

  const columns = [
    {
      id: 'customerOrganization',
      label: 'Customer Organization'
    },
    {
      id: 'fullName',
      label: 'Name'
    },
    {
      id: 'list',
      label: 'Pilot Certifications'
    },
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 25
  });
  const [pilots, setPilots] = useState([]);
  const [actionType, setActionType] = useState('');
  const [pilotId, setPilotId] = useState(null);
  const [customerOrg, setCustomerOrg] = useState([]);
  const { companyId } = useParams();
  const [pilot, setPilot] = useState({});

  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
    setPilot({});
  };

  const handleChangePage = async (event, page) => {
    setPagination({ ...pagination, page });
  };

  const handleSave = async (pilotDialog) => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/pilots/companyId/${companyId}`, {
          ...pilotDialog
        });
        handleGoToDetail(response.data);

        break;
      }

      case 'Edit': {
        const response = await axios.put(`/api/pilots/${pilotId}/companyId/${companyId}`, {
          ...pilotDialog
        });
        const tempArray = [...pilots];
        const index = pilots.findIndex((e) => e.id === pilotId);
        tempArray[index] = {
          ...response.data
        };
        setPilots(tempArray);

        break;
      }

      case 'Delete': {
        await axios.delete(`/api/pilots/${pilotId}/companyId/${companyId}`);
        setPagination({ ...pagination, count: pagination.count - 1 });
        const tempArray = [...pilots];
        const index = tempArray.findIndex((e) => e.id === pilotId);
        tempArray.splice(index, 1);
        setPilots(tempArray);
        handleChangePage(null, 0);
        setPagination({ ...pagination, page: 0, count: pagination.count - 1 });
        break;
      }
    }
    handleClosedialog();
  };

  const handleEdit = (editPilot) => {

    const propPilot = {
      firstname: editPilot.firstname,
      lastname: editPilot.lastname,
      email: editPilot.email,
      phone: editPilot.phone,
      cellphone: editPilot.cellphone,
      dateofbirth: editPilot?.dateofbirth || null,
      totalHours: editPilot?.totalHours,
      retractGear: editPilot?.retractGear,
      multiEngine: editPilot?.multiEngine,
      idCustomerOrganization: editPilot.idCustomerOrganization,
      PilotCerts: editPilot.PilotCerts.map((cert) => cert.Options.id),
      pilotRatings: editPilot?.PilotRating?.map(rate => rate.Options.id)
    };
    setPilot(propPilot);
    setActionType('Edit');
    setPilotId(editPilot.id);
    setOpen(true);
  };

  const handleDelete = (deletePilot) => {
    setPilotId(deletePilot.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleChangeRowsPerChange = (event) => {
    setPagination({ ...pagination, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  const handleGoToDetail = (pilotDetail) => {
    navigate(`/company/${companyId}/pilots/${pilotDetail.id}`);
  };

  useEffect(() => {
    const getPilots = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/pilots/companyId/${companyId}`, {
        params: { page: pagination.page, rowsPerPage: pagination.rowsPerPage }
      });
      setPilots(result.data.pilots);
      setPagination({ ...pagination, count: result.data.count });
      setIsLoading(false);
    };

    getPilots();
  }, [companyId, pagination.rowsPerPage, pagination.page]);

  useEffect(() => {
    const getCustomerOrgs = async () => {
      const result = await axios.get(`/api/customer-org/companyId/${companyId}`);
      setCustomerOrg(result.data);
    };

    getCustomerOrgs();
  }, [companyId]);

  return (
    <>
      <Breadcrumb title="Pilots" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Pilots
        </Typography>
      </Breadcrumb>
      <Grid container wrap="nowrap" justifyContent="flex-end" sx={{ mb: 3 }} spacing={gridSpacing}>
        <Grid item>
          <PilotDialog
            open={open}
            handleClosedialog={handleClosedialog}
            handlePilotSave={handleSave}
            customerOrg={customerOrg}
            actionType={actionType}
            pilot={pilot}
          />
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        title="Pilots"
        isLoading={isLoading}
        data={pilots}
        columns={columns}
        hasPagination={true}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
        pagination={pagination}
        handleChangePage={handleChangePage}
        handleChangeRowsPerChange={handleChangeRowsPerChange}
        isDisabled={!auth.writePrivledge(auth.user, 'Pilot_Adm')}
      >
        {(row, index) => (
          <>
            <Tooltip title="Edit Pilot" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Pilot_Adm')}
                  onClick={() => handleEdit(pilots[index])}
                >
                  <EditTwoToneIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Pilot" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Pilot_Adm')}
                  onClick={() => handleDelete(pilots[index])}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Pilot Details">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(pilots[index])}>
                <TextSnippetIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default Pilots;

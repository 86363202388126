import React, { useEffect, useState } from 'react';
import initializeAxios from 'utils/axios';

// material-ui
import { Chip } from '@mui/material';
import useAuth from 'hooks/useAuth';

// project import

// assets
import CustomTable from 'component/CustomTable';
import LoadingOverlay from 'component/LoadingOverlay';
import AgentDialog from 'component/AgentDialog';

// ==============================|| CONTACT LIST ||============================== //

const AgentQuote = ({ companyId, selectedAgency, selectedAgent, activeStep, emitSelectedAgent }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const columns = [
    { id: 'displayName', label: 'Display Name' },
    { id: 'email', label: 'Email' }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  const handleClickOpendialog = () => {
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
  };


  const handleAgentSelect = (row) => {
    emitSelectedAgent(activeStep, row);
  };

  const handleSave = async (agentDialog) => {
    setIsLoading(true);
    const displayName = `${agentDialog?.firstname} ${agentDialog?.lastname}`;
    const response = await axios.post(`/api/agent/companyId/${companyId}`, {
      agent: {
        ...agentDialog,
        displayName,
        idAgency: +selectedAgency.id
      }
    });
    emitSelectedAgent(activeStep, response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    const getAgents = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/agent/agencyId/${selectedAgency.id}/companyId/${companyId}`);
      setAgents([...result.data]);
      setIsLoading(false);
    };

    getAgents();
  }, [companyId, selectedAgency]);

  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <AgentDialog open={open} agent={{}} handleClosedialog={handleClosedialog} handleAgentSave={handleSave} actionType="Create" />
      <CustomTable
        isLoading={isLoading}
        title="Agents"
        data={agents}
        columns={columns}
        hasCreateButton={true}
        hasClickableRows={true}
        emitClickedRow={handleAgentSelect}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <>
            <Chip
              label="Select"
              color="primary"
              variant={selectedAgent.id === row.id ? 'filled' : 'outlined'}
              sx={{ color: selectedAgent.id === row.id ? '#fff' : '#000' }}
              clickable
            />
          </>
        )}
      </CustomTable>
    </>
  );
};

export default AgentQuote;

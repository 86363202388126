import { Document, Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { SubjectHeader } from 'views/PDFComponents/SubjectHeader';
import {
  //calculateDeductible,
  calculateTotalDeductible,
  calculateTotalPolicyPremium,
  calculateTotalPremiumm,
  calculateTotal
} from 'utils/MathFunctions';
import { formatDate, displayPhoneNumber } from 'utils/FormatUtils';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  mainHeader: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: 'center',
    fontWeight: 'bold'
  },

  header: {
    fontSize: 16,
    textAlign: 'center',
    backgroundColor: '#d3d3d3'
  },
  divider: {
    borderTop: '3px solid black'
  },

  contentDivider: {
    borderTop: '1px solid black'
  },

  content: {
    padding: '16px 8px',
    fontSize: 12,
    gap: '10px'
  },

  infoHeader: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  areaCode: {
    marginLeft: '60px'
  },

  boldText: {
    marginTop: '4px',
    fontFamily: 'Open Sans',
    fontWeight: 'bold'
  },

  container: {
    display: 'flex',
    marginTop: '8px'
  },
  aircrafts: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center'
  },
  pilots: {
    padding: '10px 0 10px 50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  total: {
    marginTop: '8px',
    textAlign: 'center'
  },
  textBox: {
    width: '300px',
    height: '100px',
    border: '2px solid black',
    borderRadius: '10px',
    marginBottom: '16px'
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 10
  },
  tableRow: {
    flexDirection: 'row'
  },
  headerTableRow: {
    flexDirection: 'row',
    borderBottomWidth: 2
  },
  tableCell: {
    flex: 1,
    margin: 5,
    padding: 5,
    fontSize: 12
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 30,
    textAlign: 'right',
    color: 'grey',
  },
});

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
});

const PDFContent = ({ policy, policyOptions, aircrafts, creationDate }) => (
  <Document>
    <Page size="letter" style={styles.body} wrap>
      <View>
        <Text
          style={[styles.mainHeader, styles.boldText]}
        >{`${policy?.['Options_Policy_idPolicyTypeToOptions']?.val} ${policy?.['Options_Policy_idPolicyStatusToOptions']?.val}`}</Text>
        <View style={styles.infoHeader}>
          <Text>
            <Text style={styles.boldText}>Policy Number</Text> : &nbsp; {policy.policyNumber}
          </Text>
          <Text>
            <Text style={styles.boldText}>Agent</Text> : &nbsp; {policy?.companyUserDispayName}
          </Text>
          <Text>
            <Text style={styles.boldText}>Date of Creation</Text> : &nbsp; {formatDate(creationDate)}
          </Text>
        </View>
      </View>
      <View style={styles.container}>
        <SubjectHeader title={policy?.customerOrganizationName} />
        <View style={styles.content}>
          <Text>
            <Text style={styles.boldText}>Client Name insured</Text> : &nbsp;{' '}
            {policy?.clientNameInsured || policy?.customerOrganizationName}
          </Text>
          <Text>
            <Text style={styles.boldText}>Address</Text> : &nbsp; {policy?.customerOrganizationAddress1 || 'No existing Address'}
          </Text>
          <Text style={styles.areaCode}>{`${policy?.customerOrganizationCity || ''} ${policy?.customerOrganizationState || ''} ${
            policy?.customerOrganizationZip || ''
          }`}</Text>
          <Text>
            <Text style={styles.boldText}>Contact Name</Text> : &nbsp; {policy?.CustomerContactName || 'No existing Customer Contact'}
          </Text>
          <Text>
            <Text style={styles.boldText}>Contact Email</Text> : &nbsp; {policy?.customerContactEmail || 'No existing Customer email'}
          </Text>
          <Text>
            <Text style={styles.boldText}>Contact Phone Number</Text> : &nbsp;{' '}
            {policy?.customerContactPhone ? displayPhoneNumber(policy.customerContactPhone) : 'No existing Customer phone number'}
          </Text>
          <Text>
            <Text style={styles.boldText}>Coverage Effective</Text> : &nbsp;{' '}
            {`${formatDate(policy.beginDate)} - ${formatDate(policy.endDate)}`}
          </Text>
        </View>
        {policy.idInsurance && (
          <>
            <SubjectHeader title="Insurance" />
            <View style={styles.content}>
              <Text>
                <Text style={[styles.boldText, { fontSize: 16 }]}>{policy?.insuranceName}</Text>
              </Text>
              <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Text>
                  <Text style={styles.boldText}>Insurance Code</Text> : &nbsp; {policy?.insuranceCode}
                </Text>
                <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Text>
                    <Text style={styles.boldText}>Insurance Notes</Text> :{' '}
                  </Text>
                  <View style={styles.textBox}>
                    <Text style={{ padding: '4px', fontSize: 11 }}>{policy.insuranceNotes}</Text>
                  </View>
                </View>
              </View>
            </View>
          </>
        )}
        <SubjectHeader title={!(policy?.idPolicyType === 28) ? 'Aircrafts' : 'Airport'} />
        <View style={styles.content}>
          {!(policy?.idPolicyType === 28) ? (
            aircrafts?.map((aircraft) => (
              <View key={aircraft.id}>
                <Text>
                  <Text style={[styles.boldText, { fontSize: 16 }]}>
                    {aircraft?.PolicyAircraftDetails[0]?.AircraftDetails?.registrationNo}
                  </Text>
                </Text>
                <Text>
                  <Text style={{ fontSize: 12 }}>
                    {'Pilots: ' +
                      aircraft.AircraftPilotDetails?.map((item) => `${item?.PilotDetails?.firstname} ${item?.PilotDetails?.lastname}`).join(', ')}
                  </Text>
                </Text>
                <View style={styles.table}>
                  <View style={styles.headerTableRow}>
                    <View style={styles.tableCell}>
                      <Text>Premium Name</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>Amount</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text>Aircraft Liability</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>{aircraft.idLiabilityAmount ? `$ ${aircraft.idLiabilityAmount}` : '$ 0.00'}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text>Medical Payments</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>{aircraft.idMedicalAmount ? `$ ${aircraft.idMedicalAmount}` : '$ 0.00'}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text>Physical Damage</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>{aircraft.idHullAmount ? `$ ${aircraft.idHullAmount}` : '$ 0.00'}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text>Covered Territory Amount</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>{aircraft.idTerritoryAmount ? `$ ${aircraft.idTerritoryAmount}` : '$ 0.00'}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text>War/Tria Coverage</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>{aircraft.idWarTriaAmount ? `$ ${aircraft.idWarTriaAmount}` : '$ 0.00'}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text>Covered Use</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>{aircraft.idUseAmount ? `$ ${aircraft.idUseAmount}` : '$ 0.00'}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.table}>
                  <View style={styles.headerTableRow}>
                    <View style={styles.tableCell}>
                      <Text>Deductibles</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>Amount</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text>Not-In-Motion</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>{aircraft.idHullNIMDeductible ? `$ ${aircraft.idHullNIMDeductible}` : 'Not Included'}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text>In-Motion</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>{aircraft.idHullIMDeductible ? `$ ${aircraft.idHullIMDeductible}` : 'Not Included'}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.table}>
                  <View style={styles.headerTableRow}>
                    <View style={styles.tableCell}>
                      <Text>Taxes and Total</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>Amount</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text>{"Taxes and Fee's"}</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>{aircraft.taxesFees ? `$ ${aircraft.taxesFees}` : 'Not Included'}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text>Total</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text>${calculateTotal(aircraft)}</Text>
                    </View>
                  </View>
                </View>
              </View>
            ))
          ) : (
            <View>
              <Text>
                <Text style={[styles.boldText, { fontSize: 16 }]}>{policy?.airportName}</Text>
              </Text>
              <View style={styles.table}>
                <View style={styles.headerTableRow}>
                  <View style={styles.tableCell}>
                    <Text>Premium Name</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>Amount</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text>Airport Liability</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{policy.airportLiabilityAmount ? `$ ${policy.airportLiabilityAmount}` : '$ 0.00'}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text>Medical Payments</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{policy.airportMedicalAmount ? `$ ${policy.airportMedicalAmount}` : '$ 0.00'}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text>Physical Damage</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{policy.airportHullAmount ? `$ ${policy.airportHullAmount}` : '$ 0.00'}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text>Covered Territory Amount</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{policy.airportTerritoryAmount ? `$ ${policy.airportTerritoryAmount}` : '$ 0.00'}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text>War/Tria Coverage</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{policy.airportWarTriaAmount ? `$ ${policy.airportWarTriaAmount}` : '$ 0.00'}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text>Covered Use</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{policy.airportUseAmount ? `$ ${policy.airportUseAmount}` : '$ 0.00'}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.table}>
                <View style={styles.headerTableRow}>
                  <View style={styles.tableCell}>
                    <Text>Description</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>Amount</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text>Total</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>${calculateTotal(policy)}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}
          <Text style={styles.contentDivider} />
          {!(policy?.idPolicyType === 28) && (
            <View>
              <Text style={{ textAlign: 'center' }}>
                <Text style={[styles.boldText, { fontSize: 14, marginTop: '8px' }]}>
                  Total Aircraft Premium : &nbsp; ${calculateTotalPremiumm(policy.PolicyAircraft)}
                </Text>
              </Text>
              <Text>
                <Text style={{ textAlign: 'center' }}>
                  <Text style={[styles.boldText, { fontSize: 14, marginTop: '8px' }]}>
                    Total Aircraft Deductible : &nbsp; ${calculateTotalDeductible(policy.PolicyAircraft)}
                  </Text>
                </Text>
              </Text>
            </View>
          )}
        </View>
        {policyOptions?.length > 0 && (
          <>
            <SubjectHeader title="Policy Options" />
            <View style={styles.table}>
              <View style={styles.headerTableRow}>
                <View style={styles.tableCell}>
                  <Text>Description</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>Amount</Text>
                </View>
              </View>
            </View>
            {policyOptions.map((po) => (
              <View key={po.id} style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>{po.description}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{po.premium ? `$ ${po.premium}` : 'Not Included'}</Text>
                </View>
              </View>
            ))}
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text style={[styles.boldText, { fontSize: 16 }]}>Total Premium</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={[styles.boldText, { fontSize: 16 }]}>${calculateTotalPremiumm(policyOptions)}</Text>
              </View>
            </View>
          </>
        )}
        <View style={[styles.header, { marginTop: '50px' }]}>
          <Text style={styles.divider} />
          <Text style={styles.boldText}>Total Policy Premium</Text>
          <Text style={styles.divider} />
        </View>
        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
          <Text>
            <Text style={[styles.boldText, { fontSize: 16 }]}>
              ${calculateTotalPolicyPremium(policy?.idPolicyType === 28 ? [policy] : policy.PolicyAircraft, policyOptions)}
            </Text>
          </Text>
        </View>
      </View>
      <Text style={styles.footer} fixed >{policy?.companyName}</Text>
    </Page>
  </Document>
);

export default PDFContent;

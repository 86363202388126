import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid } from '@mui/material';

// project import
import useAuth from 'hooks/useAuth';

// assets
import Google from 'assets/images/social-google.svg';
import initializeAxios from 'utils/axios';

// ==============================|| FIREBASE LOGIN ||============================== //

const GoogleLogin = ({isLinkGoogle=false}) => {
  const theme = useTheme();
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const { login } = useAuth();

  const googleHandler = async () => {
    try {
      await login();
    } catch (err) {
      console.error(err);
    }
  };

  const googleLinkHandler = async () => {
    const response = await axios.get('/api/user/googleLink');
    const { url } = response.data;
    window.location.href = url;
  }

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Button
            fullWidth={true}
            sx={{
              fontSize: { md: '1rem', xs: '0.875rem' },
              fontWeight: 500,
              backgroundColor: theme.palette.grey[50],
              color: theme.palette.grey[600],
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: theme.palette.grey[100]
              }
            }}
            onClick={isLinkGoogle ? googleLinkHandler : googleHandler}
            size="large"
            variant="contained"
          >
            <img
              src={Google}
              alt="google"
              width="20px"
              style={{
                marginRight: '16px',
                '@media (maxWidth:899.95px)': {
                  marginRight: '8px'
                }
              }}
            />{' '}
            {isLinkGoogle ? 'Link your Google Account' : 'Sign in with Google'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default GoogleLogin;

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import initializeAxios from 'utils/axios';

// material-ui
import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
  DialogActions,
  Button,
  Slide
} from '@mui/material';
import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';

// project import
import Breadcrumb from 'component/Breadcrumb';
import { gridSpacing } from 'config.js';
// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LoadingOverlay from 'component/LoadingOverlay';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
// ==============================|| CONTACT LIST ||============================== //
const Insurance = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const columns = [
    {
      id: 'companyName',
      label: 'Company Name'
    },
    {
      id: 'name',
      label: 'Insurance Name'
    },
    {
      id: 'code',
      label: 'Code'
    }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionType, setActionType] = useState('');
  const [insurances, setInsurances] = useState([]);
  const [insuranceId, setInsuranceId] = useState(null);
  const [insurance, setInsurance] = useState({});
  const { companyId } = useParams();

  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
    setInsurance({});
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setInsurance({
      ...insurance,
      [event.target.name]: value
    });
  };
  const handleSave = async () => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/insurance/companyId/${companyId}`, {
          insurance: {
            ...insurance,
            idCompany: +companyId
          }
        });
        setInsurances([...insurances, response.data]);
        break;
      }

      case 'Edit': {
        const response = await axios.put(`/api/insurance/${insuranceId}/companyId/${companyId}`, {
          insurance
        });
        const tempArray = [...insurances];
        const index = tempArray.findIndex((e) => e.id === insuranceId);
        tempArray[index] = {
          ...tempArray[index],
          ...response.data
        };
        setInsurances(tempArray);

        break;
      }

      case 'Delete': {
        await axios.delete(`/api/insurance/${insuranceId}/companyId/${companyId}`);
        const tempArray = [...insurances];
        const index = tempArray.findIndex((e) => e.id === insuranceId);
        tempArray.splice(index, 1);
        setInsurances(tempArray);
        break;
      }
    }
    handleClosedialog();
  };

  const handleEdit = (editInsurance) => {
    const propInsurance = {
      name: editInsurance.name,
      code: editInsurance.code
    };
    setInsurance(propInsurance);
    setActionType('Edit');
    setInsuranceId(editInsurance.id);
    setOpen(true);
  };

  const handleDelete = (deleteInsurance) => {
    setInsuranceId(deleteInsurance.id);
    setActionType('Delete');
    setOpen(true);
  };

  useEffect(() => {
    const getInsurances = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/insurance/companyId/${companyId}`);
      setInsurances([...result.data]);
      setIsLoading(false);
    };

    getInsurances();
  }, [companyId]);

  return (
    <>
      <Breadcrumb title="Insurance" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Insurance
        </Typography>
      </Breadcrumb>
      <Grid container wrap="nowrap" justifyContent="flex-end" sx={{ mb: 3 }} spacing={gridSpacing}>
        <Grid item>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClosedialog}
            sx={{
              '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                '& .MuiDialog-paper': {
                  m: 0,
                  borderRadius: 0,
                  maxWidth: 450,
                  maxHeight: '100%',
                  height: '100vh',
                  width: { xs: '100vw' }
                }
              }
            }}
          >
            <DialogTitle>
              {actionType === 'Create'
                ? 'New Insurance'
                : actionType === 'Edit'
                ? 'Edit Insurance'
                : actionType === 'Delete'
                ? 'Delete Insurance'
                : ''}
            </DialogTitle>
            <DialogContent>
              {actionType !== 'Delete' ? (
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="name"
                      label="Insurance Name"
                      variant="outlined"
                      onChange={handleChange}
                      value={insurance.name || ''}
                      error={!insurance?.name}
                      helperText={!insurance?.name ? 'Cannot Be Empty' : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      name="code"
                      label="Insurance Code"
                      variant="outlined"
                      value={insurance.code || ''}
                      error={!insurance?.code}
                      helperText={!insurance?.code ? 'Cannot Be Empty' : ''}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              ) : (
                <DialogContentText>Confirm if you want to delete this Insurance</DialogContentText>
              )}
            </DialogContent>
            <DialogActions className="actions">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={actionType !== 'Delete' ? !insurance?.name || !insurance?.code : false}
              >
                {actionType !== 'Delete' ? 'Save' : 'Delete'}
              </Button>
              <Button variant="text" onClick={handleClosedialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        title="Insurance"
        isLoading={isLoading}
        data={insurances}
        columns={columns}
        hasPagination={false}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
        isDisabled={!auth.writePrivledge(auth.user, 'Insurance_Adm')}
      >
        {(row, index) => (
          <>
            <Tooltip title="Edit Insurance" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Insurance_Adm')}
                  onClick={() => handleEdit(insurances[index])}
                >
                  <EditTwoToneIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Insurance" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Insurance_Adm')}
                  onClick={() => handleDelete(insurances[index])}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default Insurance;

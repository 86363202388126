import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, IconButton } from '@mui/material';

// project import
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { drawerWidth } from 'config.js';

// assets
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { useNavigate } from 'react-router';
import logo from 'assets/images/Aircraft-Insurance-Logo-White.png';

// ==============================|| HEADER ||============================== //

const Header = ({ drawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/dashboard/default');
  };
  return (
    <>
      <Box width={drawerWidth} sx={{ zIndex: 1201 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Grid item>
              <Box mt={0.5}>
                <IconButton color="inheirt" onClick={handleClick}>
                  <img src={logo} alt="Logo" width="200" />
                </IconButton>
              </Box>
            </Grid>
          </Box>
          <Grid item>
            <IconButton
              edge="start"
              sx={{ mr: theme.spacing(1.25), mt: 0.5 }}
              color="inherit"
              aria-label="open drawer"
              onClick={drawerToggle}
              size="large"
            >
              <MenuTwoToneIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box>
        <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
          <SearchSection theme="light" />
          <NotificationSection />
          <ProfileSection />
        </Grid>
      </Box>
    </>
  );
};

Header.propTypes = {
  drawerToggle: PropTypes.func
};

export default Header;

import React, { useEffect, useState } from 'react';
import initializeAxios from 'utils/axios';

// material-ui
import { Chip } from '@mui/material';
import useAuth from 'hooks/useAuth';
import CustomTable from 'component/CustomTable';

// project import

// assets
import LoadingOverlay from 'component/LoadingOverlay';
import LienholderDialog from 'component/Lienholder';

// ==============================|| lienHolder LIST ||============================== //

const AircraftQuoteLienHolder = ({ companyId, emitSelectedInterest, selectedInterests, activeStep, currentAircraft }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const columns = [
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'address1',
      label: 'Address 1'
    }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lienHolders, setLienHolders] = useState([]);

  const handleClickOpendialog = () => {
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
  };

  const handleSave = async (lienHolderDialog) => {
    setIsLoading(true);
    const response = await axios.post(`/api/lienholder/companyId/${companyId}`, {
      ...lienHolderDialog
    });
    setLienHolders([...lienHolders, response.data])
    emitSelectedInterest(activeStep, response.data)

    handleClosedialog();
    setIsLoading(false);
  };

  const handleSelectInterest = (row) => {
    emitSelectedInterest(activeStep, row);
  };

  useEffect(() => {
    const getCustomerOrg = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/lienHolder/companyId/${companyId}/workflow/aircraftId/${currentAircraft.id}`);
      setLienHolders([...result.data]);
      setIsLoading(false);
    };

    getCustomerOrg();
  }, [companyId]);

  return (
    <>
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        title="Other Interests"
        isLoading={isLoading}
        data={lienHolders}
        columns={columns}
        hasClickableRows={true}
        handleOpenCreate={handleClickOpendialog}
        hasCreateButton={true}
        emitClickedRow={handleSelectInterest}
      >
        {(row) => (
          <>
            <Chip
              label="Select"
              color="primary"
              variant={selectedInterests.find((item) => item.id === row.id) ? 'filled' : 'outlined'}
              sx={{ color: selectedInterests.find((item) => item.id === row.id) ? '#fff' : '#000' }}
              clickable
              onClick={() => handleSelectInterest(row)}
            />
          </>
        )}
      </CustomTable>
      <LienholderDialog
        open={open}
        handleClosedialog={handleClosedialog}
        lienholderState={{}}
        handleLienholderSave={handleSave}
        actionType="Create"
      />
    </>
  );
};

export default AircraftQuoteLienHolder;

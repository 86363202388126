import {
  Grid,
  Card,
  CardHeader,
  Typography,
  Divider,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  IconButton,
  Tooltip
} from '@mui/material';
import CustomTableCell from 'component/CustomTableCell';
import TablePaginationActions from 'component/TablePaginationActions';
import Add from '@mui/icons-material/Add';
import { AccessTime } from '@mui/icons-material';
import { displayPhoneNumber, formatDate } from 'utils/FormatUtils';

const CustomTable = ({
  title,
  columns,
  data,
  isLoading,
  children,
  hasActions = true,
  hasCreateButton = false,
  handleOpenCreate = () => {},
  hasUpdateHours = false,
  handleUpdateHours = () => {},
  hasPagination = false,
  hasClickableRows = false,
  emitClickedRow = () => {},
  pagination = {},
  isDisabled = null,
  handleChangePage = () => {},
  handleChangeRowsPerChange = () => {}
}) => {
  // Display Map
  const columnDisplayMap = {
    list: (row, title) => displayList(row, title),
    type: (row) => displayTypes(row),
    // Add more mappings for other columns
    customerOrganization: (row) => displayCustomerOrganizationName(row),
    agentDisplayName: (row, title) => displayAgentsName(row, title),
    agentName: (row) => displayAgentUserProfile(row),
    fullName: (row) => displayFullName(row),
    beginDate: (row) => formatDate(row.beginDate),
    endDate: (row) => formatDate(row.endDate),
    dateofbirth: (row) => formatDate(row.dateofbirth),
    claimDate: (row) => formatDate(row.claimDate),
    invoiceDate: (row) => formatDate(row.invoiceDate),
    billDate: (row) => formatDate(row.billDate),
    dateReceieved: (row) => formatDate(row.dateReceived),
    companyName: (row) => displayCompanyName(row),
    accept: (row) => displayAccept(row),
    policyStatus: (row) => displayPolicyStatus(row),
    customerContact: (row) => displayCustomerContactName(row),
    nestedPolicyNumber: (row) => displayPolicyNumber(row),
    amount: (row) => displayAmount(row.amount),
    nestedClaimStatus: (row) => displayNestedClaimStatus(row),
    agencyName: (row) => displayAgencyName(row)
  };

  // Display Functions
  const displayList = (list, type) => {
    return type !== 'Pilots'
      ? list?.['UserProfile_RoleAssignment']?.map((e) => e.Role.name).join(', ')
      : list?.PilotCerts.map((e) => e.Options.val).join(', ');
  };

  const displayTypes = (types) => {
    return types?.Options?.val;
  };

  const displayFullName = (row) => {
    return `${row?.firstname} ${row?.lastname}`;
  };

  const displayCustomerOrganizationName = (row) => {
    return row?.CustomerOrganization?.name;
  };

  const displayCompanyName = (row) => {
    return row?.Company?.name;
  };

  const displayAccept = (row) => {
    return row?.accept ? 'true' : 'false';
  };

  const displayAgentsName = (row, type) => {
    const typeText = type.includes('Aircraft')
      ? 'Aircraft'
      : type.includes('Policy')
      ? 'Policy'
      : type.includes('Claim')
      ? 'Claim'
      : 'Pilot';
    return row?.[`UserProfile_${typeText}_idCompanyUserToUserProfile`]?.displayName;
  };

  const displayPolicyStatus = (row) => {
    return row?.['Options_Policy_idPolicyStatusToOptions']?.val;
  };

  const displayNestedClaimStatus = (row) => {
    return row?.Options?.val;
  };

  const isPhoneNumberColumn = (columnId) => /phone|Phone/.test(columnId);

  const displayCustomerContactName = (row) => {
    return row?.CustomerContact?.name;
  };

  const displayPolicyNumber = (row) => {
    return row?.Policy?.policyNumber;
  };

  const displayAmount = (amount) => {
    const newAmount = +amount;
    return newAmount.toFixed(2);
  };

  const displayAgencyName = (row) => {
    return row?.Agency?.name;
  };

  const displayAgentUserProfile = (row) => {
    return row?.Agent?.displayName;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <>
                <Typography
                  component="div"
                  className="card-header"
                  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Typography component="div" className="card-header">
                    {title}
                  </Typography>
                  <Typography component="div">
                    {hasCreateButton && (
                      <Tooltip title={`Add ${title}`} placement="top">
                        <span>
                          <IconButton
                            color="primary"
                            aria-label="Create"
                            size="large"
                            sx={{ mr: 'auto' }}
                            disabled={isDisabled ? isDisabled : false}
                            onClick={handleOpenCreate}
                          >
                            <Add />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                    {hasUpdateHours && (
                      <Tooltip title={`Update Total Pilot Hours`} placement="top">
                        <span>
                          <IconButton
                            color="primary"
                            aria-label="Update Pilot Hours"
                            size="large"
                            sx={{ mr: 'auto' }}
                            onClick={handleUpdateHours}
                          >
                            <AccessTime />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </Typography>
                </Typography>
              </>
            }
          />
          <Divider />
          <TableContainer>
            <Table size="medium" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="right">
                      {column.label}
                    </TableCell>
                  ))}
                  {hasActions && <CustomTableCell align="right">Actions</CustomTableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  []
                ) : data.length ? (
                  data?.map((row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        cursor: hasClickableRows ? 'pointer' : 'inherit',
                        '&:hover': { backgroundColor: hasClickableRows && 'rgba(128, 128, 128, 0.1)' }
                      }}
                      onClick={() => hasClickableRows && emitClickedRow(row)}
                    >
                      {columns.map((column) => (
                        <TableCell key={column.id} align="right">
                          {columnDisplayMap[column.id]
                            ? columnDisplayMap[column.id](row, title)
                            : isPhoneNumberColumn(column.id)
                            ? displayPhoneNumber(row)
                            : row[column.id]}
                        </TableCell>
                      ))}
                      {hasActions && <TableCell align="right">{children(row, index)}</TableCell>}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>{`Please add ${title} to table`}</TableCell>
                  </TableRow>
                )}
              </TableBody>
              {hasPagination && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[25, 50, 100]}
                      count={pagination?.count}
                      page={pagination?.page}
                      rowsPerPage={pagination?.rowsPerPage}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page'
                        },
                        native: true
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerChange}
                      ActionsComponent={TablePaginationActions}
                      sx={(theme) => ({
                        '.MuiTablePagination-spacer': { display: 'flex', [theme.breakpoints.down('sm')]: { display: 'none' } }
                      })}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CustomTable;

import * as actionTypes from './actions';

const notificationReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATION:
      return [...action.payload];
    case actionTypes.ADD_NOTIFICATION:
      return [...state, action.payload];
    case actionTypes.REMOVE_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.payload.id);
    default:
      return state;
  }
};

export default notificationReducer;

import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import initializeAxios from 'utils/axios';

// material-ui
import { Grid, Typography, IconButton, Tooltip, Tab, Tabs, Hidden, Menu, MenuItem } from '@mui/material';
import useAuth from 'hooks/useAuth';

// project import
import Breadcrumb from 'component/Breadcrumb';
import { gridSpacing } from 'config.js';
import LoadingOverlay from 'component/LoadingOverlay';
import { styled } from '@mui/material';
import PolicyDialog from 'component/PolicyDialog';
import CustomTable from 'component/CustomTable';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import MenuIcon from '@mui/icons-material/Menu';

const AccountTabs = styled((props) => <Tabs {...props} />)(() => ({
  marginBottom: '24px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  '.MuiButtonBase-root': {
    minWidth: '120px',
    minHeight: 'auto',
    '.MuiTouchRipple-root': {
      flexDirection: 'row',
      '.MuiSvgIcon-root': {
        marginRight: '10px',
        marginBottom: '0 !important'
      }
    }
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

// ==============================|| CONTACT LIST ||============================== //
const Policy = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const axios = initializeAxios(auth);

  const columns = [
    {
      id: 'customerOrganization',
      label: 'Customer Organization'
    },
    {
      id: 'agentDisplayName',
      label: "Agent's Display Name"
    },
    {
      id: 'policyNumber',
      label: 'Policy Number'
    },
    {
      id: 'policyStatus',
      label: 'Policy Status'
    },
    {
      id: 'beginDate',
      label: 'Begin Date'
    },
    {
      id: 'endDate',
      label: 'End Date'
    }
  ];
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 25
  });
  const [policies, setPolicies] = useState([]);
  const [actionType, setActionType] = useState('');
  const [policyId, setPolicyId] = useState(null);
  const [customerOrg, setCustomerOrg] = useState([]);
  const [users, setUsers] = useState([]);
  const { companyId } = useParams();
  const [policy, setPolicy] = useState({});
  const [value, setValue] = useState(0);
  const [filter, setFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };

  const handleDrawerOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerClose = () => {
    setAnchorEl(null);
  };

  const handleClosedialog = () => {
    setOpen(false);
    setPolicy({});
  };

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 0: {
        setFilter('Quote');
        break;
      }
      case 1: {
        setFilter('Policy');
        break;
      }
      case 2: {
        setFilter('Expire');
        break;
      }
      case 3: {
        setFilter('All');
        break;
      }
    }
    setValue(newValue);
    setAnchorEl(null);
    setPagination({ ...pagination, page: 0 });
  };

  const handleChangePage = async (event, page) => {
    setPagination({ ...pagination, page });
  };

  const handleSave = async (policyDialog) => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/policy/companyId/${companyId}`, {
          ...policyDialog
        });
        handleGoToDetail(response.data);

        break;
      }

      case 'Edit': {
        const response = await axios.put(`/api/policy/${policyId}/companyId/${companyId}`, {
          ...policyDialog
        });
        const tempArray = [...policies];
        const index = policies.findIndex((e) => e.id === policyId);
        tempArray[index] = {
          ...tempArray[index],
          ...response.data
        };
        setPolicies(tempArray);

        break;
      }

      case 'Delete': {
        await axios.delete(`/api/policy/${policyId}/companyId/${companyId}`, {
          policy: {
            declinedReason: policyDialog?.declinedReason || ''
          }
        });
        const tempArray = [...policies];
        const index = policies.findIndex((e) => e.id === policyId);
        tempArray.splice(index, 1);
        setPolicies(tempArray);
        handleChangePage(null, 0);
        setPagination({ ...pagination, page: 0, count: pagination.count - 1 });
        break;
      }
    }
    handleClosedialog();
  };

  const handleCopyPolicy = async (copyPolicy) => {
    const tempObj = {
      policy: {
        idCustomerOrganization: copyPolicy.idCustomerOrganization,
        idAgent: copyPolicy.idAgent,
        idParentPolicy: copyPolicy?.id,
        clientNameInsured: copyPolicy.clientNameInsured,
        endorsements: copyPolicy?.endorsements || '',
        idInsurance: copyPolicy?.idInsurance || null,
        insuranceNotes: copyPolicy?.insuranceNotes || '',
        beginDate: copyPolicy?.beginDate || '',
        endDate: copyPolicy?.endDate || '',
        idPolicyType: copyPolicy.idPolicyType,
        policyNumber: copyPolicy?.policyNumber || '',
        totalAnnualPremium: copyPolicy?.totalAnnualPremium,
        totalHullPremium: copyPolicy?.totalHullPremium,
        totalLiabilityPremium: copyPolicy?.totalLiabilityPremium,
        totalOtherPremium: copyPolicy?.totalOtherPremium,
        idPolicyStatus: copyPolicy?.idPolicyStatus,
        trainingRequired: copyPolicy.trainingRequired
      }
    };

    const response = await axios.post(`/api/policy/companyId/${companyId}`, {
      ...tempObj
    });
    setPolicy({ ...response.data });
    setPolicyId(response.data.id);
    setActionType('Edit');
    setPagination({ ...pagination, page: 0 });
    setOpen(true);
  };

  const handleEdit = (editPolicy) => {
    setPolicy({ ...editPolicy });
    setActionType('Edit');
    setPolicyId(editPolicy.id);
    setOpen(true);
  };

  const handleDelete = (deletePolicy) => {
    setPolicyId(deletePolicy.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleGoToDetail = (policyDetail) => {
    navigate(`/company/${companyId}/policy/${policyDetail.idImmutable}`, {
      state: { customerOrg, users }
    });
  };

  const handleChangeRowsPerChange = (event) => {
    setPagination({ ...pagination, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  useEffect(() => {
    const getPolicies = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/policy/companyId/${companyId}`, {
        params: {
          page: pagination.page,
          rowsPerPage: pagination.rowsPerPage,
          filter
        }
      });
      setPolicies(result.data.policies);
      setPagination({ ...pagination, count: result.data.count });
      setIsLoading(false);
    };

    getPolicies();
  }, [companyId, pagination.rowsPerPage, filter, pagination.page]);

  useEffect(() => {
    const getCustomerOrgs = async () => {
      const result = await axios.get(`/api/customer-org/companyId/${companyId}`);
      setCustomerOrg(result.data);
    };

    const getCompanyUserProfiles = async () => {
      const result = await axios.get(`/api/company/${companyId}/users`);
      setUsers(result.data.UserProfile);
    };

    getCustomerOrgs();
    getCompanyUserProfiles();
  }, [companyId]);

  return (
    <>
      <Breadcrumb title="Policies" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Policies
        </Typography>
      </Breadcrumb>
      <Hidden smUp>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
            <MenuIcon sx={{ fontSize: '1.75rem' }} />
          </IconButton>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleDrawerClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
          PaperProps={{
            style: {
              width: '200px'
            }
          }}
          sx={{ mt: 1 }}
        >
          <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 0} onClick={(e) => handleTabChange(e, 0)}>
            {"My Quote's"}
          </MenuItem>
          <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 1} onClick={(e) => handleTabChange(e, 1)}>
            My Active Policies
          </MenuItem>
          <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 2} onClick={(e) => handleTabChange(e, 2)}>
            My Expiring Policies
          </MenuItem>
          <MenuItem sx={{ gap: '8px', fontSize: '12px' }} selected={value === 3} onClick={(e) => handleTabChange(e, 3)}>
            All Policies
          </MenuItem>
        </Menu>
      </Hidden>
      <Hidden smDown>
        <AccountTabs value={value} indicatorColor="primary" textColor="primary" onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label="My Quote's" {...a11yProps(0)} />
          <Tab label="My Active Policies" {...a11yProps(1)} />
          <Tab label="My Expiring Policies" {...a11yProps(2)} />
          <Tab label="All Policies" {...a11yProps(3)} />
        </AccountTabs>
      </Hidden>
      <Grid container wrap="nowrap" justifyContent="flex-end" sx={{ mb: 3 }} spacing={gridSpacing}>
        <Grid item>
          <PolicyDialog
            open={open}
            handleClosedialog={handleClosedialog}
            handlePolicySave={handleSave}
            customerOrg={customerOrg}
            actionType={actionType}
            policy={policy}
            users={users}
            companyId={companyId}
          />
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        title="Policy"
        isLoading={isLoading}
        data={policies}
        columns={columns}
        hasPagination={true}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
        pagination={pagination}
        handleChangePage={handleChangePage}
        handleChangeRowsPerChange={handleChangeRowsPerChange}
        isDisabled={!auth.writePrivledge(auth.user, 'Policy_Adm')}
      >
        {(row, index) => (
          <>
            <Tooltip title="Edit Policy" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Policy_Adm')}
                  onClick={() => handleEdit(policies[index])}
                >
                  <EditTwoToneIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Policy" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Policy_Adm')}
                  onClick={() => handleDelete(policies[index])}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Policy Details">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(policies[index])}>
                <TextSnippetIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy Policy">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleCopyPolicy(policies[index])}>
                <ContentCopyTwoToneIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default Policy;

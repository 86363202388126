import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  DialogActions,
  Button,
  DialogContentText,
  Slide,
  FormControl
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { useParams } from 'react-router';
import { gridSpacing } from 'config.js';

import { deactiveArrayUtil } from 'utils/deactiveArrayUtil';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const FormControlCustom = styled((props) => <FormControl {...props} />)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 160,
  maxWidth: 500,
  width: '100%'
}));

const AirportDialog = ({ open, handleClosedialog, data, handleAirportSave, actionType, isAirportQuote = false }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const { companyId } = useParams();

  const [agencies, setAgencies] = useState([]);
  const [agents, setAgents] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [agencyId, setAgencyId] = useState(null);

  const [airport, setAirport] = useState({});

  const handleSave = () => {
    handleAirportSave(airport);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'idAgency') {
      setAgencyId(value);
      setAirport({
        ...airport,
        idAgent: '',
        [name]: value
      });
    } else {
      setAirport({
        ...airport,
        [name]: value
      });
    }
  };

  useEffect(() => {
    if (data) {
      setAirport(data);
    }
  }, []);

  useEffect(() => {
    const getAgencies = async () => {
      const result = await axios.get(`/api/agency/companyId/${companyId}`);
      setAgencies(result.data);
    };

    const getAgents = async () => {
      const response = await axios.get(`/api/agent/companyId/${companyId}`);
      setAgents([...response.data]);
    };

    const getCompanyUserProfiles = async () => {
      const result = await axios.get(`/api/company/${companyId}/users`);
      setCompanyUsers(result.data.UserProfile);
    };

    getAgencies();
    getAgents();
    getCompanyUserProfiles();
  }, [companyId]);
  const saveInput = React.createRef();
  return (
    <Dialog
      open={open}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          saveInput.current.click();
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClosedialog}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          height: '100vh',
          '& .MuiDialog-paper': {
            m: 0,
            borderRadius: 0,
            maxWidth: 450,
            maxHeight: '100%',
            height: '100vh',
            width: { xs: '100vw' }
          }
        }
      }}
    >
      <DialogTitle>
        {actionType === 'Create' ? 'New Airport' : actionType === 'Edit' ? 'Edit Airport' : actionType === 'Delete' ? 'Delete Airport' : ''}
      </DialogTitle>
      <DialogContent>
        {actionType !== 'Delete' ? (
          <Grid container spacing={gridSpacing}>
            {!isAirportQuote && (
              <>
                <Grid item xs={12} className="fixSelector">
                  <Typography variant="subtitle2">
                    <FormControlCustom variant="standard">
                      <InputLabel>Agency Name</InputLabel>
                      <Select
                        value={airport?.idAgency || ''}
                        onChange={handleChange}
                        name="idAgency"
                        label="Agency Name"
                        error={!airport?.idAgency}
                      >
                        {deactiveArrayUtil(agencies, {
                          id: airport?.idAgency,
                          name: airport?.Agency?.name
                        }).map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {!airport.idAgency && <FormHelperText style={{ color: '#ff413a' }}>Agency cannot be Empty</FormHelperText>}
                    </FormControlCustom>
                  </Typography>
                </Grid>
                <Grid item xs={12} className="fixSelector">
                  {agents?.length > 0 && (
                    <Typography variant="subtitle2">
                      <FormControlCustom variant="standard">
                        <InputLabel id="demo-simple-select-outlined-label">Agent Display Name</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={airport?.idAgent || ''}
                          onChange={handleChange}
                          name="idAgent"
                          label="Agent's Display Name"
                          error={!airport?.idAgent}
                          disabled={!airport?.idAgency}
                        >
                          {deactiveArrayUtil(agents, {
                            id: airport?.idAgent,
                            displayName: airport?.Agent?.displayName
                          })
                            ?.filter((item) => item.idAgency === agencyId)
                            ?.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.displayName}
                              </MenuItem>
                            ))}
                        </Select>
                        {!airport.idAgent && <FormHelperText style={{ color: '#ff413a' }}>Agent cannot be Empty</FormHelperText>}
                      </FormControlCustom>
                    </Typography>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} className="fixSelector">
              <Typography variant="subtitle2">
                <FormControlCustom variant="standard">
                  <InputLabel id="demo-simple-select-outlined-label">Company User Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={airport?.idCompanyUser || ''}
                    error={!airport?.idCompanyUser}
                    onChange={handleChange}
                    name="idCompanyUser"
                    label="Company User"
                  >
                    {deactiveArrayUtil(companyUsers, {
                      id: airport?.idCompanyUser,
                      displayName: airport?.UserProfile?.displayName
                    }).map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                  {!airport.idCompanyUser && <FormHelperText style={{ color: '#ff413a' }}>Company User cannot be Empty</FormHelperText>}
                </FormControlCustom>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                name="code"
                label="IATA Code"
                variant="outlined"
                value={airport.code || ''}
                error={airport?.code?.trim().length !== 3}
                onChange={handleChange}
                helperText={airport?.code?.trim().length !== 3 ? 'IATA Code must be 3 Characters long' : ''}
              />
            </Grid>
          </Grid>
        ) : (
          <DialogContentText>Confirm if you want to delete this airport</DialogContentText>
        )}
      </DialogContent>
      <DialogActions className="actions">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={
            actionType !== 'Delete'
              ? isAirportQuote
                ? airport?.code?.trim().length !== 3 || !airport?.idCompanyUser
                : airport?.code?.trim().length !== 3 || !airport?.idAgent || !airport?.idAgency || !airport?.idCompanyUser
              : false
          }
        >
          {actionType !== 'Delete' ? 'Save' : 'Delete'}
        </Button>
        <Button variant="text" onClick={handleClosedialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AirportDialog;

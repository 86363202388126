import React, { useState, useRef, useEffect, useReducer } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  List,
  ListItemText,
  Typography,
  ListItemButton,
  Grid,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  MenuItem
} from '@mui/material';

// third party
import PerfectScrollbar from 'react-perfect-scrollbar';

// assets
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import notificationReducer from 'store/notificationReducer';

import * as actionTypes from 'store/actions';
import { useDispatch } from 'react-redux';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const theme = useTheme();
  const auth = useAuth();
  const userId = auth?.user?.id || auth?.user?.user?.id;
  const companyId = auth?.user?.idCompany || auth?.user?.user?.idCompany;
  const axios = initializeAxios(auth);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [notifications, dispatch] = useReducer(notificationReducer, []);
  const dispatchSnack = useDispatch();
  const [task, setTask] = useState({});
  const [isForm, setIsForm] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setIsForm(false);
    setTask({});
  };

  const handleDelete = async (event, item) => {
    event.stopPropagation();

    await axios.delete(`/api/notifications/${item.id}`);
    dispatch({
      type: actionTypes.REMOVE_NOTIFICATION,
      payload: item
    });
  };

  const handleCreateTask = () => {
    setOpenModal(true);
    setIsForm(true);
  };

  const handleClearTasks = () => {
    setOpenModal(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTask({
      ...task,
      [name]: value
    });
  };

  const handleModalSave = async () => {
    if (isForm) {
      await axios.post(`/api/notifications/user`, {
        notification: {
          idProfile: task?.idUser,
          displayText: task?.displayText,
          isLink: false
        }
      });
      dispatchSnack({
        type: actionTypes.SNACKBAR_OPEN,
        open: true,
        message: 'Task has been created',
        anchorOrigin: { vertical: 'top', horizontal: 'center' }
      });
    } else {
      await axios.delete(`/api/notifications/user/${userId}/all`);
      dispatch({
        type: actionTypes.SET_NOTIFICATION,
        payload: []
      });
    }
    handleModalClose();
  };

  const handleNavigate = async (item) => {
    if (item.isLink) {
      navigate(item.url);
      await axios.delete(`/api/notifications/${item.id}`);
      dispatch({
        type: actionTypes.REMOVE_NOTIFICATION,
        payload: item
      });
    }
  };

  useEffect(() => {
    const getNotifications = async () => {
      const response = await axios.get(`/api/notifications/user/${userId}`);
      dispatch({
        type: actionTypes.SET_NOTIFICATION,
        payload: response.data
      });
    };
    getNotifications();
    const intervalId = setInterval(getNotifications, 30000);
   return () => clearInterval(intervalId);
  }, []);

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const getUsers = async () => {
      const response = await axios.get(`/api/company/${companyId}/users`);
      setUsers(response.data.UserProfile);
    };
    getUsers();
  }, [openModal]);
  return (
    <>
      <Button
        sx={{
          minWidth: { sm: 50, xs: 35 }
        }}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-label="Notification"
        onClick={handleToggle}
        color="inherit"
      >
        <NotificationsNoneTwoToneIcon sx={{ fontSize: '1.5rem' }} />
      </Button>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10]
            }
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true // false by default
            }
          }
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 350,
                    minWidth: 250,
                    backgroundColor: theme.palette.background.paper,
                    pb: 0,
                    borderRadius: '10px'
                  }}
                >
                  <PerfectScrollbar style={{ height: 320, overflowX: 'hidden' }}>
                    {notifications?.length > 0 && (
                      <ListItemButton sx={{ pt: 0 }} onClick={handleClearTasks}>
                        <ListItemText
                          sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                          primary={<Typography variant="subtitle1">Clear Notifications</Typography>}
                        />
                      </ListItemButton>
                    )}
                    {notifications?.map((item) => (
                      <ListItemButton key={item.id * Math.random()} sx={{ pt: 0 }} onClick={() => handleNavigate(item)}>
                        <ListItemText
                          primary={<Typography variant="subtitle1">{item?.createdBy}</Typography>}
                          secondary={<Typography variant="subtitle2">{item?.displayText}</Typography>}
                        />
                        <ListItemSecondaryAction sx={{ top: 20 }}>
                          <Grid container justifyContent="flex-end">
                            <Grid item>
                              <IconButton onClick={(e) => handleDelete(e, item)} sx={{ ':hover': { color: 'red' } }}>
                                <Close />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    ))}
                    <ListItemButton sx={{ pt: 0 }} onClick={handleCreateTask}>
                      <ListItemText
                        sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                        primary={<Typography variant="subtitle1">Create a Task</Typography>}
                      />
                    </ListItemButton>
                  </PerfectScrollbar>
                </List>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Dialog open={openModal} onClose={handleModalClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{isForm ? 'Create a Task' : 'Delete Notifications'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isForm
              ? 'To create a Task. Please select who you want the task to be sent to. Then input the message that you want them to see.'
              : 'Please confirm if you want to delete all notifications'}
          </DialogContentText>
          {isForm && (
            <>
              <TextField
                select
                fullWidth
                sx={{ mt: 1 }}
                label="Recipient User"
                name="idUser"
                value={task?.idUser || ''}
                onChange={handleChange}
              >
                {users?.length > 0 ? (
                  users
                    ?.filter((user) => user.id !== userId)
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item?.displayName}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem>There are no Users</MenuItem>
                )}
              </TextField>
              <TextField sx={{ mt: 1 }} fullWidth label="Message" name="displayText" variant="outlined" onChange={handleChange} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleModalSave} color="primary" disabled={isForm ? !task.idUser || !task.displayText : false}>
            {isForm ? 'Save' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationSection;

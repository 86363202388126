import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Mui Imports
import { Grid, Typography, Card, CardContent, TextField, Divider, Button, MenuItem, Tooltip, IconButton } from '@mui/material';
import Breadcrumb from 'component/Breadcrumb';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';
import { validRegex } from 'utils/FormatUtils';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';

const policyColumns = [
  {
    id: 'policyNumber',
    label: 'Policy Number'
  },
  {
    id: 'policyStatus',
    label: 'Policy Status'
  },
  {
    id: 'agentDisplayName',
    label: 'Agents Display Name'
  },
];


const AgentDetails = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const axios = initializeAxios(auth);
  const { companyId, agentId, agencyId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [agent, setAgent] = useState({});
  const [agencies, setAgencies] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [policyPagination, setPolicyPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 25
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAgent({
      ...agent,
      [name]: value
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    const response = await axios.put(`/api/agent/${agentId}/companyId/${companyId}`, {
      agent: {
        idAgency: agent?.idAgency,
        displayName: agent?.displayName,
        firstname: agent?.firstname,
        lastname: agent?.lastname,
        email: agent?.email
      }
    });
    setAgent({ ...response.data });
    setIsLoading(false);
  };

  const handlePolicyChangeRowsPerChange = (event) => {
    setPolicyPagination({ ...policyPagination, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  const handlePolicyChangePage = async (event, page) => {
    setPolicyPagination({ ...policyPagination, page });
  };

  const handleGoToDetail = (policy) => {
    navigate(`/company/${companyId}/policy/${policy.id}`);
  };

  useEffect(() => {
    const getAgent = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/agent/${agentId}/companyId/${companyId}`);
      setAgent({ ...response.data });
      setPolicies([...response.data.Policy.filter((item) => item.CustomerOrganization.idCompany == companyId)]);
      setPolicyPagination({
        ...policyPagination,
        count: response?.data?.Policy?.filter((item) => item.CustomerOrganization.idCompany == companyId).length
      });
      setIsLoading(false);
    };

    const getAgencies = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/agency/companyId/${companyId}`);
      setAgencies([...response.data]);
      setIsLoading(false);
    };

    getAgent();
    getAgencies();
  }, [companyId]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb title="Agent Details" divider={false} sx={{ mt: { lg: 0, xs: 2 } }}>
            <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
              Home
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/agency`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Agencies
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/agency/${agencyId}`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Agency Details
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/agency/${agencyId}/agents`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Agents
            </Typography>
            <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
              Agent Details
            </Typography>
          </Breadcrumb>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <Grid item lg={8} xs={12}>
        <Grid container spacing={gridSpacing} xs={12} item sx={{ flexWrap: { lg: 'nowrap', sm: 'wrap' } }} className="grid-container">
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Card>
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          {agencies?.length > 0 && (
                            <TextField
                              fullWidth
                              name="idAgency"
                              label="Agency"
                              variant="outlined"
                              value={agent?.idAgency || ''}
                              error={!agent?.idAgency}
                              helperText={!agent?.idAgency ? 'Cannot be Empty' : ''}
                              select
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                              }}
                              onChange={handleChange}
                            >
                              {agencies.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <TextField
                            fullWidth
                            label="Display Name"
                            name="displayName"
                            variant="outlined"
                            value={agent?.displayName || ''}
                            error={!agent?.displayName}
                            helperText={!agent?.displayName ? 'Cannot be Empty' : ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                            }}
                            onChange={handleChange}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing} sx={{ mt: 1 }}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          sx={{ mt: 1 }}
                          label="First Name"
                          name="firstname"
                          variant="outlined"
                          value={agent?.firstname || ''}
                          error={!agent?.firstname}
                          helperText={!agent?.firstname ? 'Cannot be Empty' : ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Last Name"
                          name="lastname"
                          variant="outlined"
                          value={agent?.lastname || ''}
                          error={!agent?.lastname}
                          helperText={!agent?.lastname ? 'Cannot be Empty' : ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Email"
                          name="email"
                          variant="outlined"
                          value={agent?.email || ''}
                          error={(agent?.email?.length > 0 ? !agent?.email?.match(validRegex) : false) || !agent.email}
                          helperText={
                            (agent.email?.length > 0 ? (!agent?.email?.match(validRegex) ? 'Invalid Emial' : '') : '') ||
                            (!agent.email ? 'Email can not be empty' : '')
                          }
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                      <Grid item sm={6} md={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSave}
                          disabled={
                            !agent?.displayName ||
                            (agent?.email?.length > 0 ? !agent?.email?.match(validRegex) : false) ||
                            !agent.email ||
                            !agent?.idAgency ||
                            !agent?.firstname ||
                            !agent?.lastname ||
                            !auth.writePrivledge(auth.user, 'Agency_Adm')
                          }
                        >
                          Save Changes
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <CustomTable
                  columns={policyColumns}
                  data={policies.slice(
                    policyPagination?.page * policyPagination?.rowsPerPage,
                    (policyPagination?.page + 1) * policyPagination?.rowsPerPage
                  )}
                  isLoading={isLoading}
                  hasPagination={true}
                  pagination={policyPagination}
                  handleChangePage={handlePolicyChangePage}
                  handleChangeRowsPerChange={handlePolicyChangeRowsPerChange}
                  title="Policy"
                >
                  {(row) => (
                    <>
                      <Tooltip title="Policy Details">
                        <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row)}>
                          <TextSnippetIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </CustomTable>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AgentDetails;

/* eslint-disable no-unused-vars */
// react Imports
import React, { useEffect, useState } from 'react';
// Mui Imports
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Input,
  Slide,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogContentText,
  Autocomplete,
  InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { states } from 'utils/states';
import { isValid, parse } from 'date-fns';
import { validRegex, phoneNumberRegex } from 'utils/FormatUtils';

// Local Imports
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';
import { useParams } from 'react-router';
import { deactiveArrayUtil } from 'utils/deactiveArrayUtil';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const FormControlCustom = styled((props) => <FormControl {...props} />)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 160,
  maxWidth: 500,
  width: '100%'
}));

const CustomDialogTitle = styled((props) => <DialogTitle {...props} />)(() => ({
  padding: 0
}));

const excludedValidation = ['regiesteredEmail', 'registeredPhone', 'registeredAddress2', 'hangered', 'isLeased'];
let MAX_VALIDATION = 14;

const AircraftDialog = ({
  open,
  handleClosedialog,
  aircraft,
  handleAircraftSave,
  customerOrg = [],
  actionType,
  customerOrgId = null,
  aircraftRelations = [],
  isPolicyAircraft = false
}) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const [newAircraft, setNewAircraft] = useState({});
  const [validation, setValidation] = useState({
    isError: true
  });
  // const [pilots, setPilots] = useState([]);
  const [aircrafts, setAircrafts] = useState([]);

  const [aircraftLiabilities, setAircraftLiabilities] = useState([]);
  const [aircraftHulls, setAircraftHulls] = useState([]);
  const [aircraftMedicals, setAircraftMedicals] = useState([]);
  const [aircraftTerritories, setAircraftTerritories] = useState([]);
  const [aircraftUses, setAircraftUses] = useState([]);
  const [aircraftWarTrias, setAircraftWarTrias] = useState([]);

  const { companyId } = useParams();

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedValidation = { ...validation };
    if (!excludedValidation.includes(name)) {
      if (isNaN(value) && typeof value === 'string') {
        if (value?.trim() === '') {
          updatedValidation[name] = true;
        } else {
          updatedValidation[name] = false;
        }
      } else if (!isNaN(value) && typeof value === 'string') {
        if (+value <= 0 || /\d+\.\d{2}/gm.test(value)) {
          updatedValidation[name] = true;
        } else {
          updatedValidation[name] = false;
        }
      } else {
        if (!value || value.length === 0) {
          updatedValidation[name] = true;
        } else {
          updatedValidation[name] = false;
        }
      }
    }

    // might need to revamp this at some point but right now it works with it
    // basically this is here to validate that validation will equal to the number of required fields for aircraft
    if (Object.values(updatedValidation).length === MAX_VALIDATION) {
      updatedValidation['isError'] = false;
    }
    setNewAircraft({
      ...newAircraft,
      [name]: value
    });

    setValidation(updatedValidation);
  };

  const handleSave = () => {
    let returnAircraft;
    if (customerOrgId && !isPolicyAircraft) {
      returnAircraft = {
        aircraft: {
          idAircraft: newAircraft.idAircraft
        }
      };
    } else if (isPolicyAircraft) {
      returnAircraft = {
        aircraft: {
          idAircraft: newAircraft?.idAircraft,
          idLiability: newAircraft?.idLiability,
          idLiabilityAmount: newAircraft?.idLiabilityAmount,
          idMedical: newAircraft?.idMedical,
          idMedicalAmount: newAircraft?.idMedicalAmount,
          idHull: newAircraft?.idHull,
          idHullAmount: newAircraft?.idHullAmount,
          idTerritory: newAircraft?.idTerritory,
          idTerritoryAmount: newAircraft?.idTerritoryAmount,
          idUse: newAircraft?.idUse,
          idUseAmount: newAircraft?.idUseAmount,
          idWarTria: newAircraft?.idWarTria,
          idWarTriaAmount: newAircraft?.idWarTriaAmount,
          taxesFees: newAircraft?.taxesFees,
          idHullIMDeductible: newAircraft?.idHullIMDeductible,
          idHullNIMDeductible: newAircraft?.idHullNIMDeductible
        }
      };
    } else {
      returnAircraft = {
        aircraft: {
          registrationNo: newAircraft.registrationNo,
          hangered: newAircraft.hangered === 'true' ? true : false,
          make: newAircraft.make,
          seats: +newAircraft.seats,
          type: newAircraft.type,
          value: +newAircraft.value,
          year: +newAircraft.year,
          airportCode: newAircraft.airportCode,
          idCustomerOrganization: newAircraft.idCustomerOrganization,
          registeredOwner: newAircraft.registeredOwner,
          registeredEmail: newAircraft.registeredEmail,
          registeredPhone: newAircraft.registeredPhone,
          registeredAddress1: newAircraft.registeredAddress1,
          registeredAddress2: newAircraft.registeredAddress2 || '',
          registeredCity: newAircraft.registeredCity,
          registeredState: newAircraft.registeredState,
          registeredZip: newAircraft.registeredZip,
          isLeased: newAircraft?.isLeased === 'true' ? true : false
        }
      };
    }
    handleAircraftSave(returnAircraft);
  };

  const handleDateChange = (event) => {
    const updatedValidation = { ...validation };
    console.log(updatedValidation);
    let year;
    if (isValid(event)) {
      year = event?.getFullYear()?.toString();
    } else {
      year = '';
    }
    if (year?.toString().match(/^\d{4}$/)) {
      updatedValidation['year'] = false;
    }

    if (Object.values(updatedValidation).length === MAX_VALIDATION) {
      updatedValidation['isError'] = false;
    }

    setNewAircraft({
      ...newAircraft,
      year
    });

    setValidation(updatedValidation);
  };

  const parsedValue = (value) => (isValid(parse(value, 'yyyy', new Date())) ? parse(value, 'yyyy', new Date()) : '');

  const handleClose = () => {
    setValidation({});
    setNewAircraft({});
    handleClosedialog();
  };

  useEffect(() => {
    // const getPilots = async () => {
    //   const result = await axios.get(`/api/pilots/companyId/${companyId}/all`);
    //   setPilots(result.data);
    // };
    if (Object.values(aircraft).length > 0) {
      const propAircraft = { ...aircraft };
      setNewAircraft(propAircraft);
      setValidation({ ...validation, isError: false });
    }
    if (actionType === 'CreateWorkflow') {
      MAX_VALIDATION = 12;
    }
    // getPilots();
  }, [aircraft]);

  useEffect(() => {
    const getLiabilities = async () => {
      const response = await axios.get(`/api/poaLiability/companyId/${companyId}`);
      setAircraftLiabilities([...response.data]);
    };

    const getHulls = async () => {
      const response = await axios.get(`/api/poaHull/companyId/${companyId}`);
      setAircraftHulls([...response.data]);
    };

    const getMedical = async () => {
      const response = await axios.get(`/api/poaMedical/companyId/${companyId}`);
      setAircraftMedicals([...response.data]);
    };

    const getTerritory = async () => {
      const response = await axios.get(`/api/poaTerritory/companyId/${companyId}`);
      setAircraftTerritories([...response.data]);
    };

    const getUse = async () => {
      const response = await axios.get(`/api/poaUse/companyId/${companyId}`);
      setAircraftUses([...response.data]);
    };

    const getWarTria = async () => {
      const response = await axios.get(`/api/poaWarTria/companyId/${companyId}`);
      setAircraftWarTrias([...response.data]);
    };
    getLiabilities();
    getHulls();
    getMedical();
    getTerritory();
    getUse();
    getWarTria();
  }, [companyId]);
  const saveInput = React.createRef();

  useEffect(() => {
    const getAircrafts = async () => {
      const relations = aircraftRelations?.map((air) => air.Aircraft.id) || [];
      const result = await axios.get(`/api/aircraft/companyId/${companyId}/customerOrgId/${customerOrgId}`);
      let tempArray = [...result.data];
      tempArray = tempArray.filter((air) => (relations.includes(air.id) ? false : true));
      setAircrafts(tempArray);
    };

    if (customerOrgId) {
      getAircrafts();
    }
  }, [customerOrgId, aircraftRelations]);

  return (
    <Dialog
      open={open}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          saveInput.current.click();
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          height: '100vh',
          '& .MuiDialog-paper': {
            m: 0,
            borderRadius: 0,
            maxWidth: 450,
            minWidth: 200,
            maxHeight: '100vh',
            height: '100vh',
            width: { xs: '100vw' }
          }
        }
      }}
    >
      <DialogTitle>
        {actionType === 'Create' || (actionType === 'Details' && newAircraft?.actionType !== 'Edit') || actionType === 'CreateWorkflow'
          ? 'New Aircraft'
          : actionType === 'Edit' || newAircraft?.actionType === 'Edit'
          ? 'Edit Aircraft'
          : actionType === 'Delete'
          ? 'Delete Aircraft'
          : ''}
      </DialogTitle>
      <DialogContent>
        {actionType !== 'Delete' ? (
          actionType === 'Details' ? (
            <Grid container spacing={gridSpacing}>
              {newAircraft?.actionType !== 'Edit' && (
                <Grid item xs={12}>
                  <Autocomplete
                    sx={{ mt: 1 }}
                    fullWidth
                    options={aircrafts}
                    getOptionLabel={(option) => ` ${option.id}: ${option.registrationNo}`}
                    value={aircrafts.find((pil) => pil.id === newAircraft.idAircraft) || null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {
                      const selectedAircraftId = newValue ? newValue.id : null;
                      handleChange({ target: { name: 'idAircraft', value: selectedAircraftId } });
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!newAircraft?.idAircraft}
                        helperText={!newAircraft?.idAircraft ? 'Must not be Emoty' : ''}
                        {...params}
                        label="Aircrafts"
                      />
                    )}
                  />
                </Grid>
              )}
              {isPolicyAircraft && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      value={newAircraft?.idLiability || ''}
                      label="Aircraft Liability"
                      name="idLiability"
                      onChange={handleChange}
                    >
                      {deactiveArrayUtil(aircraftLiabilities, {
                        id: newAircraft?.idLiability,
                        shortCode: newAircraft?.PolicyOptionAircraftLiability?.shortCode
                      })?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.shortCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Liability Amount"
                      name="idLiabilityAmount"
                      value={newAircraft?.idLiabilityAmount || ''}
                      type="number"
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0, step: 0.01 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      value={newAircraft?.idHull || ''}
                      label="Aircraft Hull"
                      name="idHull"
                      onChange={handleChange}
                    >
                      {deactiveArrayUtil(aircraftHulls, {
                        id: newAircraft?.idHull,
                        shortCode: newAircraft?.PolicyOptionAircraftHull?.shortCode
                      })?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.shortCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Hull Amount"
                      name="idHullAmount"
                      value={newAircraft?.idHullAmount || ''}
                      type="number"
                      onChange={handleChange}
                      InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      value={newAircraft?.idMedical || ''}
                      label="Aircraft Medical"
                      name="idMedical"
                      onChange={handleChange}
                    >
                      {deactiveArrayUtil(aircraftMedicals, {
                        id: newAircraft?.idMedical,
                        shortCode: newAircraft?.PolicyOptionAircraftMedical?.shortCode
                      }).map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.shortCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Medical Amount"
                      name="idMedicalAmount"
                      value={newAircraft?.idMedicalAmount || ''}
                      type="number"
                      onChange={handleChange}
                      InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      value={newAircraft?.idTerritory || ''}
                      label="Aircraft Territory"
                      name="idTerritory"
                      onChange={handleChange}
                    >
                      {deactiveArrayUtil(aircraftTerritories, {
                        id: newAircraft?.idTerritory,
                        shortCode: newAircraft?.PolicyOptionAircraftTerritory?.shortCode
                      }).map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.shortCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="Territory Amount"
                      name="idTerritoryAmount"
                      value={newAircraft?.idTerritoryAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      value={newAircraft?.idUse || ''}
                      label="Aircraft Use"
                      name="idUse"
                      onChange={handleChange}
                    >
                      {deactiveArrayUtil(aircraftUses, {
                        id: newAircraft?.idUse,
                        shortCode: newAircraft?.PolicyOptionAircraftUse?.shortCode
                      }).map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.shortCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Use Amount"
                      name="idUseAmount"
                      value={newAircraft?.idUseAmount || ''}
                      type="number"
                      onChange={handleChange}
                      InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      value={newAircraft?.idWarTria || ''}
                      label="Aircraft WarTria"
                      name="idWarTria"
                      onChange={handleChange}
                    >
                      {deactiveArrayUtil(aircraftWarTrias, {
                        id: newAircraft?.idWarTria,
                        shortCode: newAircraft?.PolicyOptionAircraftWarTria?.shortCode
                      }).map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.shortCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="WarTria Amount"
                      name="idWarTriaAmount"
                      value={newAircraft?.idWarTriaAmount || ''}
                      type="number"
                      onChange={handleChange}
                      InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Hull NIM Deductible"
                      name="idHullNIMDeductible"
                      value={newAircraft?.idHullNIMDeductible || ''}
                      type="number"
                      onChange={handleChange}
                      InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Hull IM Deductible"
                      name="idHullIMDeductible"
                      value={newAircraft?.idHullIMDeductible || ''}
                      type="number"
                      onChange={handleChange}
                      InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Taxes and Fee's"
                      name="taxesFees"
                      value={newAircraft?.taxesFees || ''}
                      type="number"
                      onChange={handleChange}
                      InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Registation Number"
                  name="registrationNo"
                  variant="outlined"
                  required
                  value={newAircraft?.registrationNo || ''}
                  error={!newAircraft?.registrationNo?.trim()}
                  helperText={!newAircraft?.registrationNo?.trim() ? 'First Name cannot be empty' : ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomDialogTitle>Hangered</CustomDialogTitle>

                <FormControl component="fieldset">
                  <RadioGroup aria-label="gender" name="hangered" value={newAircraft?.hangered || false} onChange={handleChange}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FormControlLabel value={true} control={<Radio color="default" />} label="Yes" />
                      </Grid>
                      <Grid item>
                        <FormControlLabel value={false} control={<Radio color="default" />} label="No" />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <CustomDialogTitle>Leased</CustomDialogTitle>

                <FormControl component="fieldset">
                  <RadioGroup aria-label="leased" name="isLeased" value={newAircraft?.isLeased || false} onChange={handleChange}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FormControlLabel value={true} control={<Radio color="default" />} label="Yes" />
                      </Grid>
                      <Grid item>
                        <FormControlLabel value={false} control={<Radio color="default" />} label="No" />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} className="fixSelector">
                <FormControlCustom variant="standard">
                  <InputLabel id="demo-mutiple-name-label">Registered Pilots</InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id={`demo-simple-select-${!newAircraft?.pilotAircraft?.length ? 'error' : 'outlined'}`}
                    multiple
                    name="pilotAircraft"
                    value={newAircraft?.pilotAircraft || []}
                    required
                    onChange={handleChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                  >
                    {pilots.map((pilot) => (
                      <MenuItem key={pilot.id} value={pilot.id}>
                        {`${pilot.firstname} ${pilot.lastname}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {!newAircraft?.pilotAircraft?.length && (
                    <FormHelperText style={{ color: '#ff413a' }}>Pilot Certifications cannot be Empty</FormHelperText>
                  )}
                </FormControlCustom>
              </Grid> */}
              {actionType !== 'CreateWorkflow' && (
                <Grid item xs={12} className="fixSelector">
                  <FormControlCustom variant="standard">
                    <InputLabel id="demo-simple-select-outlined-label">Customer Organizations</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id={`demo-simple-select-${!newAircraft?.idCustomerOrganization ? 'error' : 'outlined'}`}
                      value={newAircraft?.idCustomerOrganization || ''}
                      onChange={handleChange}
                      name="idCustomerOrganization"
                      input={<Input />}
                      error={!newAircraft?.idCustomerOrganization}
                    >
                      {deactiveArrayUtil(customerOrg, {
                        id: newAircraft?.idCustomerOrganization,
                        name: newAircraft?.CustomerOrganization?.name
                      }).map((org) => (
                        <MenuItem key={org.id} value={org.id}>
                          {org.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {!newAircraft.idCustomerOrganization && (
                      <FormHelperText style={{ color: '#ff413a' }}>Customer Organizations cannot be Empty</FormHelperText>
                    )}
                  </FormControlCustom>
                </Grid>
              )}
              <DialogTitle>Add Aircraft Indetification</DialogTitle>
              <Grid item xs={12} className="textFieldUnderTitle">
                <TextField
                  fullWidth
                  required
                  label="Make"
                  name="make"
                  variant="outlined"
                  value={newAircraft.make || ''}
                  onChange={handleChange}
                  error={!newAircraft?.make?.trim()}
                  helperText={!newAircraft?.make?.trim() ? 'Invalid Make' : ''}
                  sx={{ pb: 2 }}
                />
                <TextField
                  fullWidth
                  required
                  type="number"
                  name="seats"
                  label="Number of Seats"
                  variant="outlined"
                  value={newAircraft?.seats || ''}
                  error={!newAircraft?.seats}
                  helperText={!newAircraft?.seats ? 'Invalid Number' : ''}
                  inputProps={{ inputMode: 'numeric' }}
                  onChange={handleChange}
                  sx={{ pb: 2 }}
                />
                <TextField
                  fullWidth
                  required
                  label="Type"
                  name="type"
                  variant="outlined"
                  value={newAircraft.type || ''}
                  onChange={handleChange}
                  error={!newAircraft?.type?.trim()}
                  helperText={!newAircraft?.type?.trim() ? 'Invalid Type' : ''}
                  sx={{ pb: 2 }}
                />
                <TextField
                  fullWidth
                  required
                  type="number"
                  label="Value"
                  name="value"
                  variant="outlined"
                  value={newAircraft.value || ''}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start" sx={{ marginTop: -0.1 }}>
                        $
                      </InputAdornment>
                    )
                  }}
                  error={!newAircraft?.value}
                  helperText={!newAircraft?.value ? 'Invalid Value' : ''}
                  onChange={handleChange}
                  sx={{ pb: 2 }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Year*"
                    openTo="year"
                    views={['year']}
                    inputFormat="yyyy"
                    value={parsedValue(newAircraft?.year)}
                    renderInput={(props) => (
                      <TextField
                        name="year"
                        error={!newAircraft?.year?.toString().match(/^\d{4}$/)}
                        sx={{
                          pb: 2
                          // '& .MuiOutlinedInput-notchedOutline': {
                          //   borderColor: newAircraft?.year?.toString().match(/^\d{4}$/) ? 'red' : 'black'
                          // }
                        }}
                        onChange={handleChange}
                        helperText={!newAircraft?.year?.toString().match(/^\d{4}$/) ? 'Invalid Year' : ''}
                        fullWidth
                        {...props}
                      />
                    )}
                    disableMaskedInput
                    onChange={handleDateChange}
                  />
                </LocalizationProvider>
                <TextField
                  fullWidth
                  required
                  label="Airport Code"
                  name="airportCode"
                  variant="outlined"
                  value={newAircraft.airportCode || ''}
                  onChange={handleChange}
                  error={newAircraft?.airportCode?.trim()?.length !== 3}
                  helperText={newAircraft?.airportCode?.trim()?.length !== 3 ? 'Airport Code has to be 3 characters' : ''}
                  sx={{ pb: 2 }}
                />
              </Grid>
              <DialogTitle>Add Registered Address</DialogTitle>
              <Grid item xs={12} className="textFieldUnderTitle">
                <TextField
                  fullWidth
                  required
                  sx={{ mb: 1 }}
                  name="registeredOwner"
                  label="Registered Owner"
                  variant="outlined"
                  error={!newAircraft?.registeredOwner?.trim()}
                  helperText={!newAircraft?.registeredOwner?.trim() ? 'Invalid Name' : ''}
                  value={newAircraft.registeredOwner || ''}
                  onChange={handleChange}
                />
                <TextField
                  sx={{ mb: 1 }}
                  fullWidth
                  label="Email"
                  name="registeredEmail"
                  variant="outlined"
                  input={<Input />}
                  value={newAircraft?.registeredEmail || ''}
                  error={newAircraft?.registeredEmail?.trim()?.length > 0 ? !newAircraft?.registeredEmail?.match(validRegex) : false}
                  helperText={
                    newAircraft?.registeredEmail?.trim().length > 0
                      ? !newAircraft?.registeredEmail?.match(validRegex)
                        ? 'Invalid Emial'
                        : ''
                      : ''
                  }
                  onChange={handleChange}
                />
                <TextField
                  sx={{ mb: 1 }}
                  fullWidth
                  label="Phone"
                  name="registeredPhone"
                  variant="outlined"
                  value={newAircraft.registeredPhone || ''}
                  onChange={handleChange}
                  error={newAircraft?.registeredPhone?.trim()?.length > 0 ? !newAircraft?.registeredPhone?.match(phoneNumberRegex) : false}
                  helperText={
                    newAircraft?.registeredPhone?.trim().length > 0
                      ? !newAircraft?.registeredPhone?.match(phoneNumberRegex)
                        ? 'Invalid Phone Number'
                        : ''
                      : ''
                  }
                />
                <TextField
                  fullWidth
                  sx={{ mt: 1 }}
                  name="registeredAddress1"
                  label="Address 1"
                  required
                  variant="outlined"
                  error={!newAircraft?.registeredAddress1?.trim()}
                  helperText={!newAircraft?.registeredAddress1?.trim() ? 'Invalid Address' : ''}
                  value={newAircraft.registeredAddress1 || ''}
                  onChange={handleChange}
                />
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  name="registeredAddress2"
                  label="Address 2"
                  variant="outlined"
                  value={newAircraft.registeredAddress2 || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  name="registeredCity"
                  label="City"
                  required
                  variant="outlined"
                  value={newAircraft.registeredCity || ''}
                  error={!newAircraft?.registeredCity?.trim()}
                  helperText={!newAircraft?.registeredCity?.trim() ? 'Invalid Type' : ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  value={newAircraft.registeredState || ''}
                  onChange={handleChange}
                  name="registeredState"
                  label="State"
                  error={!newAircraft?.registeredState}
                  helperText={!newAircraft?.registeredState ? 'Cannot be Empty' : ''}
                  select
                >
                  {states.map((state, index) => (
                    <MenuItem key={index} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  name="registeredZip"
                  label="Zipcode"
                  variant="outlined"
                  error={!newAircraft?.registeredZip?.trim()}
                  helperText={!newAircraft?.registeredZip?.trim() ? 'Invalid Zipcode' : ''}
                  value={newAircraft.registeredZip || ''}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          )
        ) : (
          <DialogContentText>Confirm if you want to delete the this Aircraft</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          ref={saveInput}
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={actionType !== 'Delete' ? (actionType !== 'Details' ? validation.isError : !newAircraft?.idAircraft) : false}
        >
          {actionType !== 'Delete' ? 'Save' : 'Delete'}
        </Button>
        <Button variant="text" onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AircraftDialog;

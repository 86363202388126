import React, { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
// material-ui
import {
  Grid,
  Button,
  TextField,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// project import
import { gridSpacing } from 'config.js';

// assets
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import { useParams } from 'react-router';
import { deactiveArrayUtil } from 'utils/deactiveArrayUtil';
import { validRegex, phoneNumberRegex } from 'utils/FormatUtils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const FormControlCustom = styled((props) => <FormControl {...props} />)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 160,
  maxWidth: 500,
  width: '100%'
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

// eslint-disable-next-line react/prop-types
const PilotDialog = ({
  open,
  handleClosedialog,
  handlePilotSave,
  actionType,
  pilot,
  customerOrg,
  isPolicyPilot = false,
  customerOrgId = null,
  pilotRelations = []
}) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const [newPilot, setNewPilot] = useState({});
  const [pilotCerts, setPilotCerts] = useState([]);
  const [pilotRatings, setPilotRatings] = useState([]);
  const [pilots, setPilots] = useState([]);
  const { companyId } = useParams();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewPilot({
      ...newPilot,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setNewPilot({
      ...newPilot,
      dateofbirth: date !== '' ? date : null
    });
  };

  const handleSave = () => {
    let returnPilot;

    if (customerOrgId) {
      returnPilot = {
        pilot: {
          idPilot: newPilot?.idPilot
        }
      };
    } else {
      returnPilot = {
        pilot: {
          firstname: newPilot.firstname,
          lastname: newPilot.lastname,
          email: newPilot?.email,
          phone: newPilot?.phone,
          cellphone: newPilot?.cellphone,
          dateofbirth: newPilot?.dateofbirth,
          totalHours: +newPilot?.totalHours,
          retractGear: +newPilot?.retractGear,
          multiEngine: +newPilot?.multiEngine,
          idCustomerOrganization: newPilot.idCustomerOrganization
        },
        pilotCerts: newPilot.PilotCerts,
        pilotRatings: newPilot?.pilotRatings || []
      };
    }

    if (isPolicyPilot && customerOrgId) {
      returnPilot.endorsement = newPilot?.endorsement || '';
    }
    return handlePilotSave(returnPilot);
  };

  useEffect(() => {
    const getPilotCerts = async () => {
      const result = await axios.get(`/api/options/companyId/${companyId}?category=PilotCertType`);
      setPilotCerts(result.data);
    };

    const getPilotRatings = async () => {
      const result = await axios.get(`/api/options/companyId/${companyId}?category=PilotRating`);
      setPilotRatings(result.data);
    };

    if (pilot) {
      const propPilot = { ...pilot };
      setNewPilot(propPilot);
    }

    getPilotCerts();
    getPilotRatings();
  }, [pilot]);

  useEffect(() => {
    const getPilots = async () => {
      if (customerOrgId) {
        const relations = pilotRelations?.map((rel) => rel.Pilot.id) || [];
        const result = await axios.get(`/api/pilots/companyId/${companyId}/customerOrgId/${customerOrgId}`);
        let tempArray = [...result.data];
        tempArray = tempArray.filter((pilotFilter) => (relations.includes(pilotFilter.id) ? false : true));
        setPilots(tempArray);
      }
    };
    getPilots();
  }, [customerOrgId, pilotRelations]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosedialog}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            '& .MuiDialog-paper': {
              m: 0,
              borderRadius: 0,
              maxWidth: 450,
              minWidth: 200,
              maxHeight: '100%',
              height: '100vh',
              width: { xs: '100vw' }
            }
          }
        }}
      >
        <DialogTitle>
          {actionType === 'Create' || (actionType === 'Details' && newPilot?.actionType !== 'Edit') || actionType === 'CreateWorkflow'
            ? 'New Pilot'
            : actionType === 'Edit' || newPilot?.actionType === 'Edit'
            ? 'Edit Pilot'
            : actionType === 'Delete'
            ? 'Delete Pilot'
            : ''}
        </DialogTitle>
        <DialogContent>
          {actionType !== 'Delete' ? (
            actionType === 'Details' ? (
              <Grid container spacing={gridSpacing}>
                {newPilot?.actionType !== 'Edit' && (
                  <Grid item xs={12}>
                    <Autocomplete
                      sx={{ mt: 1 }}
                      fullWidth
                      options={pilots}
                      getOptionLabel={(option) => `${option.id}: ${option.firstname} ${option.lastname}`}
                      value={pilots.find((pil) => pil.id === newPilot.idPilot) || null}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(_, newValue) => {
                        const selectedPilotId = newValue ? newValue.id : null;
                        handleChange({ target: { name: 'idPilot', value: selectedPilotId } });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!newPilot?.idPilot}
                          helperText={!newPilot?.idPilot ? 'Must not be Empty' : ''}
                          label="Pilots"
                        />
                      )}
                    />
                  </Grid>
                )}
                {isPolicyPilot && (
                  <Grid item xs={12}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Endorsements"
                      name="endorsement"
                      multiline
                      minRows={3}
                      maxRows={6}
                      value={newPilot?.endorsement || ''}
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="First Name"
                    name="firstname"
                    variant="outlined"
                    required
                    value={newPilot?.firstname || ''}
                    error={!newPilot?.firstname?.trim()}
                    helperText={!newPilot?.firstname?.trim() ? 'First Name cannot be empty' : ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Last Name"
                    name="lastname"
                    required
                    variant="outlined"
                    value={newPilot?.lastname || ''}
                    error={!newPilot?.lastname?.trim()}
                    helperText={!newPilot?.lastname?.trim() ? 'Last Name cannot be empty' : ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    variant="outlined"
                    input={<Input />}
                    value={newPilot?.email || ''}
                    error={newPilot?.email?.length > 0 ? !newPilot?.email?.match(validRegex) : false}
                    helperText={newPilot?.email?.length > 0 ? (!newPilot?.email?.match(validRegex) ? 'Invalid Emial' : '') : ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    variant="outlined"
                    value={newPilot.phone || ''}
                    onChange={handleChange}
                    error={newPilot?.phone?.length > 0 ? !newPilot?.phone?.match(phoneNumberRegex) : false}
                    helperText={
                      newPilot?.phone?.length > 0 ? (!newPilot?.phone?.match(phoneNumberRegex) ? 'Invalid Phone Number' : '') : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Cell Phone"
                    name="cellphone"
                    variant="outlined"
                    value={newPilot.cellphone || ''}
                    error={newPilot?.cellphone?.length > 0 ? !newPilot?.cellphone?.match(phoneNumberRegex) : false}
                    helperText={
                      newPilot?.cellphone?.length > 0 ? (!newPilot?.cellphone?.match(phoneNumberRegex) ? 'Invalid Phone Number' : '') : ''
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      inputFormat="MM/dd/yyyy"
                      margin="normal"
                      label="Date of Birth*"
                      renderInput={(props) => (
                        <TextField
                          fullWidth
                          error={!newPilot?.dateofbirth}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: !newPilot?.dateofbirth ? 'red' : 'black'
                            }
                          }}
                          helperText={!newPilot?.dateofbirth ? 'Cannot be Empty' : ''}
                          {...props}
                        />
                      )}
                      value={newPilot?.dateofbirth || null}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      keyboardIcon={<EventTwoToneIcon />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    type="number"
                    label="Total Hours"
                    name="totalHours"
                    variant="outlined"
                    value={newPilot?.totalHours || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    type="number"
                    label="Retract Gear"
                    name="retractGear"
                    variant="outlined"
                    value={newPilot?.retractGear || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    type="number"
                    label="Multi-Engine"
                    name="multiEngine"
                    variant="outlined"
                    value={newPilot?.multiEngine || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} className="fixSelector">
                  <FormControlCustom variant="standard">
                    <InputLabel id="demo-mutiple-name-label">Pilot Certifications*</InputLabel>
                    <Select
                      labelId="demo-mutiple-name-label"
                      id={`demo-simple-select-${!newPilot?.PilotCerts?.length ? 'error' : 'outlined'}`}
                      multiple
                      name="PilotCerts"
                      value={newPilot.PilotCerts || []}
                      required
                      onChange={handleChange}
                      input={<Input />}
                      MenuProps={MenuProps}
                    >
                      {pilotCerts.map((cert) => (
                        <MenuItem key={cert.id} value={cert.id}>
                          {cert.val}
                        </MenuItem>
                      ))}
                    </Select>
                    {!newPilot?.PilotCerts?.length && (
                      <FormHelperText style={{ color: '#ff413a' }}>Pilot Certifications cannot be Empty</FormHelperText>
                    )}
                  </FormControlCustom>
                </Grid>
                <Grid item xs={12} className="fixSelector">
                  <FormControlCustom variant="standard">
                    <InputLabel id="demo-mutiple-name-label">Pilot Ratings</InputLabel>
                    <Select
                      multiple
                      name="pilotRatings"
                      value={newPilot.pilotRatings || []}
                      required
                      onChange={handleChange}
                      input={<Input />}
                      MenuProps={MenuProps}
                    >
                      {pilotRatings.map((cert) => (
                        <MenuItem key={cert.id} value={cert.id}>
                          {cert.val}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControlCustom>
                </Grid>
                {actionType !== 'CreateWorkflow' && (
                  <Grid item xs={12} className="fixSelector">
                    <FormControlCustom variant="standard">
                      <InputLabel id="demo-simple-select-outlined-label">Customer Organizations*</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id={`demo-simple-select-${!newPilot?.idCustomerOrganization ? 'error' : 'outlined'}`}
                        value={newPilot?.idCustomerOrganization || ''}
                        onChange={handleChange}
                        name="idCustomerOrganization"
                        input={<Input />}
                        error={!newPilot?.idCustomerOrganization}
                      >
                        {deactiveArrayUtil(customerOrg, {
                          id: newPilot?.idCustomerOrganization,
                          displayName: newPilot?.CustomerOrganization?.name
                        }).map((cOrg) => (
                          <MenuItem key={cOrg.id} value={cOrg.id}>
                            {cOrg.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {!newPilot.idCustomerOrganization && (
                        <FormHelperText style={{ color: '#ff413a' }}>Customer Organizations cannot be Empty</FormHelperText>
                      )}
                    </FormControlCustom>
                  </Grid>
                )}
              </Grid>
            )
          ) : (
            <DialogContentText>Confirm if you want to delete the this Pilot</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              actionType !== 'Delete'
                ? actionType !== 'Details'
                  ? !newPilot?.firstname ||
                    !newPilot?.lastname ||
                    !newPilot?.PilotCerts?.length ||
                    (actionType !== 'CreateWorkflow' && !newPilot.idCustomerOrganization) ||
                    !newPilot?.dateofbirth
                  : !newPilot?.idPilot
                : false
            }
          >
            {actionType !== 'Delete' ? 'Save' : 'Delete'}
          </Button>
          <Button variant="text" onClick={handleClosedialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PilotDialog;

import React, { useEffect, useState } from 'react';

// material-ui
import { Chip } from '@mui/material';

// project import
import initializeAxios from 'utils/axios';

// assets
import useAuth from 'hooks/useAuth';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import CustomerOrgDialog from 'component/CustomerOrgDialog';

// ==============================|| CONTACT LIST ||============================== //

const AircraftQuoteCustomerOrg = ({ companyId, activeStep, selectedCustomerOrg, emitSelectedCustomerOrg }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const columns = [
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'type',
      label: 'Type'
    }
  ];

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerOrgs, setCustomerOrgs] = useState([]);

  const handleClickOpendialog = () => {
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
  };

  const handleCustomerOrgSelect = (row) => {
    emitSelectedCustomerOrg(activeStep, row);
  };

  const handleSave = async (customerDialog) => {
    setIsLoading(true);
    const response = await axios.post(`/api/customer-org/companyId/${companyId}`, {
      customer: {
        ...customerDialog,
        idCompany: +companyId
      }
    });

    emitSelectedCustomerOrg(activeStep, response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/customer-org/companyId/${companyId}`)
      .then((response) => {
        setCustomerOrgs(response.data);
      })
      .then(() => setIsLoading(false));

  }, [companyId]);

  return (
    <>
      <CustomerOrgDialog
        companyId={companyId}
        open={open}
        handleClosedialog={handleClosedialog}
        handleCustomerSave={handleSave}
        actionType="Create"
        customer={{}}
      />
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        isLoading={isLoading}
        data={customerOrgs}
        columns={columns}
        title="Customer Organizations"
        hasCreateButton={true}
        hasClickableRows={true}
        emitClickedRow={handleCustomerOrgSelect}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <>
            <Chip
              label="Select"
              color="primary"
              variant={selectedCustomerOrg.id === row.id ? 'filled' : 'outlined'}
              sx={{ color: selectedCustomerOrg.id === row.id ? '#fff' : '#000' }}
              clickable
            />
          </>
        )}
      </CustomTable>
    </>
  );
};

export default AircraftQuoteCustomerOrg;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// material-ui
import {
  Grid,
  Typography,
  IconButton,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  ListItem,
  List,
  ListItemText
} from '@mui/material';

// project import
import Breadcrumb from 'component/Breadcrumb';
import { gridSpacing } from 'config.js';
import initializeAxios from 'utils/axios';
// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ContactsIcon from '@mui/icons-material/Contacts';
import AddressIcon from '@mui/icons-material/Home';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import '../../assets/scss/actions.scss';
import useAuth from 'hooks/useAuth';
import LoadingOverlay from 'component/LoadingOverlay';
import CustomTable from 'component/CustomTable';
import CustomerOrgDialog from 'component/CustomerOrgDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// ==============================|| CONTACT LIST ||============================== //

const CustomerOrg = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const { companyId } = useParams();

  const columns = [
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'type',
      label: 'Type'
    }
  ];

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };

  const handleAddressDialog = (customer) => {
    setCustomer({ ...customer });
    setOpenAddress(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
    setOpenAddress(false);
    setCustomer({});
  };

  const handleSave = async (customerDialog) => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/customer-org/companyId/${companyId}`, {
          customer: {
            ...customerDialog,
            idCompany: +companyId
          }
        });
        const newCustomer = { ...response.data };
        setCustomerOrgs([...customerOrgs, newCustomer]);
        break;
      }
      case 'Edit': {
        const response = await axios.put(`/api/customer-org/${customerOrgId}/companyId/${companyId}`, {
          customer: {
            ...customerDialog
          }
        });
        const tempArray = [...customerOrgs];
        const index = tempArray.findIndex((e) => e.id === customerOrgId);
        tempArray[index] = {
          ...response.data
        };
        setCustomerOrgs(tempArray);
        break;
      }

      case 'Delete': {
        const response = await axios.delete(`/api/customer-org/${customerOrgId}/companyId/${companyId}`);
        const tempArray = [...customerOrgs];
        const index = tempArray.findIndex((e) => e.id === response.data.id);
        tempArray.splice(index, 1);
        setCustomerOrgs(tempArray);
        break;
      }
    }

    handleClosedialog();
  };

  const [customerOrgs, setCustomerOrgs] = useState([]);
  const [customer, setCustomer] = useState({});
  const [actionType, setActionType] = useState('');
  const [customerOrgId, setCustomerOrgId] = useState(null);

  const navigate = useNavigate();

  const handleEdit = (Customer) => {
    setCustomer({
      name: Customer?.name,
      orgType: +Customer?.orgType,
      address1: Customer?.address1,
      address2: Customer?.address2,
      city: Customer?.city,
      state: Customer?.state,
      zip: Customer?.zip
    });
    setCustomerOrgId(Customer.id);
    setActionType('Edit');
    setOpen(true);
  };

  const handleDelete = (customer) => {
    setCustomerOrgId(customer.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleContacts = (Customer) => {
    navigate(`/company/${companyId}/customer-org/${Customer.id}/contacts`);
  };

  const handleGoToDetail = (customer) => {
    navigate(`/company/${companyId}/customer-org/${customer.id}`);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/customer-org/companyId/${companyId}`)
      .then((response) => {
        setCustomerOrgs(response.data);
      })
      .then(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Breadcrumb title="List of Customer Organizations" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Customer Organizaitions
        </Typography>
      </Breadcrumb>
      <Grid container wrap="nowrap" justifyContent="flex-end" sx={{ mb: 3 }} spacing={gridSpacing}>
        <Grid item>
          <CustomerOrgDialog
            open={open}
            companyId={companyId}
            customer={customer}
            handleClosedialog={handleClosedialog}
            handleCustomerSave={handleSave}
            actionType={actionType}
          />

          <Dialog
            open={openAddress}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClosedialog}
            sx={{
              '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                '& .MuiDialog-paper': {
                  m: 0,
                  borderRadius: 0,
                  maxWidth: 450,
                  maxHeight: '100%',
                  height: '100vh'
                }
              }
            }}
          >
            <DialogTitle>Company/Consumer Address</DialogTitle>
            <DialogContent>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <List>
                    {customer.address1 && (
                      <ListItem>
                        <ListItemText disableTypography style={{ fontWeight: 'bold' }} primary="Address: " />
                        <ListItemText primary={customer.address1} />
                      </ListItem>
                    )}
                    {customer.address2 && (
                      <ListItem>
                        <ListItemText disableTypography style={{ fontWeight: 'bold' }} primary="Address 2: " />
                        <ListItemText primary={customer.address2} />
                      </ListItem>
                    )}
                    {customer.city && (
                      <ListItem>
                        <ListItemText disableTypography style={{ fontWeight: 'bold' }} primary="City: " />
                        <ListItemText primary={customer.city} />
                      </ListItem>
                    )}
                    {customer.state && (
                      <ListItem>
                        <ListItemText disableTypography style={{ fontWeight: 'bold' }} primary="State: " />
                        <ListItemText primary={customer.state} />
                      </ListItem>
                    )}
                    {customer.zip && (
                      <ListItem>
                        <ListItemText disableTypography style={{ fontWeight: 'bold' }} primary="Zipcode: " />
                        <ListItemText primary={customer.zip} />
                      </ListItem>
                    )}
                  </List>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="text" onClick={handleClosedialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <CustomTable isLoading={isLoading} data={customerOrgs} columns={columns} title="Customer Organizations" hasCreateButton={true} handleOpenCreate={handleClickOpendialog} isDisabled={!auth.writePrivledge(auth.user, 'CustomerOrg_Adm')}>
        {(row, index) => (
          <>
            <Tooltip title="Edit Customer" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'CustomerOrg_Adm')}
                  onClick={() => handleEdit(customerOrgs[index])}
                >
                  <EditTwoToneIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Customer" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'CustomerOrg_Adm')}
                  onClick={() => handleDelete(customerOrgs[index])}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Customer Contacts">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleContacts(customerOrgs[index])}>
                <ContactsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Customer Address">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleAddressDialog(customerOrgs[index])}>
                <AddressIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Customer Org Details">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row)}>
                <TextSnippetIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default CustomerOrg;

import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import initializeAxios from 'utils/axios';

// material-ui
import { Typography, IconButton, Tooltip } from '@mui/material';
import useAuth from 'hooks/useAuth';
import CustomTable from 'component/CustomTable';
import Breadcrumb from 'component/Breadcrumb';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import UserIcon from '@mui/icons-material/Person';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LoadingOverlay from 'component/LoadingOverlay';
import AgencyDialog from 'component/AgencyDialog';

// ==============================|| CONTACT LIST ||============================== //

const Agency = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const axios = initializeAxios(auth);

  const columns = [
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'companyName',
      label: 'Company Name'
    }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionType, setActionType] = useState('');
  const [agencies, setAgencies] = useState([]);
  const [agencyId, setAgencyId] = useState(null);

  const { companyId } = useParams();
  const [agency, setAgency] = useState({});
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
    setAgency({});
  };

  const handleSave = async (agencyDialog) => {
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/agency/companyId/${companyId}`, {
          agency: {
            ...agencyDialog,
            idCompany: +companyId
          }
        });
        setAgencies([...agencies, response.data]);
        break;
      }

      case 'Edit': {
        const response = await axios.put(`/api/agency/${agencyId}/companyId/${companyId}`, {
          agency: {
            ...agencyDialog,
            idCompany: +companyId
          }
        });
        const tempArray = [...agencies];
        const index = tempArray.findIndex((e) => e.id === agencyId);
        tempArray[index] = {
          ...response.data
        };
        setAgencies(tempArray);

        break;
      }

      case 'Delete': {
        await axios.delete(`/api/agency/${agencyId}/companyId/${companyId}`);
        const tempArray = [...agencies];
        const index = agencies.findIndex((e) => e.id === agencyId);
        tempArray.splice(index, 1);
        setAgencies(tempArray);
        break;
      }
    }
    handleClosedialog();
  };

  const handleEdit = (editAgency) => {
    const newAgency = {
      name: editAgency?.name
    };
    setAgency(newAgency);
    setActionType('Edit');
    setAgencyId(editAgency.id);
    setOpen(true);
  };

  const handleDelete = (deleteAgency) => {
    setAgencyId(deleteAgency.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleGoToDetail = (agencyDetails) => {
    navigate(`/company/${companyId}/agency/${agencyDetails.id}`);
  };

  const handleGoToAgents = (agencyDetails) => {
    navigate(`/company/${companyId}/agency/${agencyDetails.id}/agents`);
  };

  useEffect(() => {
    const getAgencies = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/agency/companyId/${companyId}`);
      setAgencies(result.data);
      setIsLoading(false);
    };

    getAgencies();
  }, [companyId]);

  return (
    <>
      <Breadcrumb title="Agencies" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Agencies
        </Typography>
      </Breadcrumb>
      <LoadingOverlay loading={isLoading} />
      <AgencyDialog
        open={open}
        handleClosedialog={handleClosedialog}
        actionType={actionType}
        handleAgencySave={handleSave}
        agency={agency}
      />
      <CustomTable
        title="Agencies"
        isLoading={isLoading}
        data={agencies}
        agency={agency}
        columns={columns}
        hasPagination={false}
        hasCreateButton={true}
        isDisabled={!auth.writePrivledge(auth.user, 'Agency_Adm')}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <>
            <Tooltip title="Edit Agency" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Agency_Adm')}
                  onClick={() => handleEdit(row)}
                >
                  <EditTwoToneIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Agency" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Agency_Adm')}
                  onClick={() => handleDelete(row)}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Agency Agents">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToAgents(row)}>
                <UserIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Agency Details">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row)}>
                <TextSnippetIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default Agency;

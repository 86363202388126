import CustomTable from 'component/CustomTable';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';

import { Chip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'component/LoadingOverlay';
import PilotDialog from 'component/PilotDialog';

const columns = [
  {
    id: 'fullName',
    label: 'Name'
  },
  {
    id: 'list',
    label: 'Pilot Certifications'
  }
];

const AircraftQuotePilots = ({ companyId, activeStep, selectedPilots, selectedAircraft, selectedCustomerOrg, emitSelectedPilot }) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pilots, setPilots] = useState([]);

  const handleSave = async (PilotDialog) => {
    setIsLoading(true);
    const pilot = { ...PilotDialog.pilot, idCustomerOrganization: +selectedCustomerOrg.id };
    const response = await axios.post(`/api/pilots/companyId/${companyId}`, {
      pilot,
      pilotCerts: PilotDialog?.pilotCerts,
      pilotRatings: PilotDialog?.pilotRatings || [],
      detailsFlag: false
    });
    emitSelectedPilot(activeStep, response.data);
    handleClosedialog();
    setIsLoading(false);
  };

  const handleClickOpendialog = () => {
    setOpen(true);
  };

  const handlePilotSelect = (row) => {
    emitSelectedPilot(activeStep, row);
  };

  const handleClosedialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getPilots = async () => {
      setIsLoading(true);
      const result = await axios.get(
        `/api/pilots/companyId/${companyId}/customerOrgId/${selectedCustomerOrg.id}/workflow/aircraftId/${selectedAircraft.id}`
      );
      setPilots(result.data);
      setIsLoading(false);
    };

    getPilots();
  }, [companyId]);

  return (
    <>
      <PilotDialog open={open} handleClosedialog={handleClosedialog} handlePilotSave={handleSave} actionType="CreateWorkflow" pilot={{}} />
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        isLoading={isLoading}
        columns={columns}
        data={pilots}
        hasClickableRows={true}
        emitClickedRow={handlePilotSelect}
        title="Pilots"
        hasPagination={false}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
      >
        {(row) => (
          <Chip
            label="Select"
            color="primary"
            variant={selectedPilots.find((item) => item.id === row.id) ? 'filled' : 'outlined'}
            sx={{ color: selectedPilots.find((item) => item.id === row.id) ? '#fff' : '#000' }}
            clickable
            onClick={() => handlePilotSelect(row)}
          />
        )}
      </CustomTable>
    </>
  );
};

export default AircraftQuotePilots;
